import { zodResolver } from "@hookform/resolvers/zod";
import { ReactNode, useEffect, useRef, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { z } from "zod";
import { PrimaryButton } from "../../../../components/global/PrimaryButton";
import { apiSlice } from "../../../../store/apiSlice";
import { TicketTitle } from "./TicketTitle";
import { useNavigate, useParams } from "react-router-dom";
import DashboardContainer from "../../../../components/amp/DashboardContainer";
import DashboardHeaderContainer from "../../../../components/amp/DashboardHeaderContainer";
import { TicketCategory } from "./TicketCategory";
import { TicketMinAge, TicketMaxAge } from "./TicketAge";
import Footer from "../../../../components/global/amp/Footer";
import moment from "moment";
import { ErrorMessage } from "../../../../components/global/ErrorMessage";
import Select from "react-select";
import MultiSelectCategoryOptionsSm from "../../../../lib/react-select/MultiSelectCategoryOptionsSm";
import { OutlineButton } from "../../../../components/global/OutlineButton";
import { TicketAvailability } from "./TicketAvailability";
import ModalWrapper from "../../../../components/global/ModalWrapper";
import { TicketCapacity } from "./TicketCapacity";
import { restartAnimation } from "../../../../components/global/amp/ToastNotification";
import { globalActions } from "../../../../store/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../../store";
import TextInput from "../../../../components/form/TextInput";

export type EditTicketFormType = z.infer<typeof schema>;

export const categories: { value: string; label: string }[] = [
  { value: "3_and_under", label: "Babies and Toddlers" },
  { value: "younger_children", label: "Child" },
  { value: "adult", label: "Adult" },
  { value: "senior", label: "Senior" },
  { value: "student", label: "Student" },
  { value: "family", label: "Family" },
  { value: "carer", label: "Carer" },
  { value: "group", label: "Group" },
];

export const ages: { value: string; label: string }[] = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18+", label: "18+" },
];

const schema = z.object({
  id: z.string().optional(),
  name: z.string().nonempty({ message: "Please enter a name." }),
  default_price: z.any(),
  product_id: z.any(),
  category: z.any(),
  description: z.string(),
  validUntil: z.string().min(1, {message: 'Required'}),
  availability: z
    .enum(["sat", "sun", "mon", "tue", "wed", "thu", "fri"], {
      invalid_type_error: "Invalid",
    })
    .array().min(1, {message: 'Required'}),
  ticketType: z.array(
    z.object({
      id: z.any(),
      category: z.string().nonempty({ message: "Please select a category." }),
      min_age: z.number().min(0).max(100),
      max_age: z.number().min(1).max(150),
      sellPrice: z.any(),
      isFree: z.boolean(),
      capacity: z.string(),
    })
  ).min(1),
  exclusions: z
    .object({
      date: z.string(),
    })
    .array(),
  cancellationPolicy: z.enum(
    ["no", "24hours", "48hours", "72hours", "anytime"],
    { invalid_type_error: "Invalid" }
  ),
});
const cancellationOptions = [
  {
    value: "no",
    label: "No Cancellations",
  },
  {
    value: "24hours",
    label: "24 Hours Before",
  },
  {
    value: "48hours",
    label: "48 Hours Before",
  },
  {
    value: "72hours",
    label: "72 Hours Before",
    isMostPopular: true,
  },
  {
    value: "anytime",
    label: "Cancel Anytime",
  },
];

const CreateTicket: React.FC = () => {
  const navigate = useNavigate();
  const { ticketId } = useParams<{ ticketId: string }>();
  const { attractionId } = useParams();

  const isEditing = !!ticketId;
  const [allDoneModal, setAllDoneModal] = useState(false);

  const [createNewProduct] = apiSlice.useCreateNewProductMutation();
  const [updateTicket, { isLoading }] = apiSlice.useUpdateTicketMutation();
  const { data: ticketQuery, error: ticketError } = apiSlice.useGetProductQuery(ticketId || "", {
    skip: !isEditing,
  });

  const formMethods = useForm<EditTicketFormType>({
    resolver: zodResolver(schema),
    defaultValues: {
      exclusions: [
        {
          date: "",
        },
      ],
      availability: [],
      ticketType: [
        {
          category: "adult",
          isFree: false,
          min_age: 15,
          max_age: 100,
          sellPrice: 24.5,
        },
      ],
    },
  });

  useEffect(() => {
    if (!ticketQuery) return;
    const availabilityPeriod = ticketQuery.periods.find(
      (p) => p.name === "validOn"
    );
    const availability: (
      | "sat"
      | "sun"
      | "mon"
      | "tue"
      | "wed"
      | "thu"
      | "fri"
    )[] = [];

    if (availabilityPeriod?.monday_availability_id) availability.push("mon");
    if (availabilityPeriod?.tuesday_availability_id) availability.push("tue");
    if (availabilityPeriod?.wednesday_availability_id) availability.push("wed");
    if (availabilityPeriod?.thursday_availability_id) availability.push("thu");
    if (availabilityPeriod?.friday_availability_id) availability.push("fri");
    if (availabilityPeriod?.saturday_availability_id) availability.push("sat");
    if (availabilityPeriod?.sunday_availability_id) availability.push("sun");

    const threeMonths = new Date();
    threeMonths.setMonth(threeMonths.getMonth() + 3)
    formMethods.reset({
      product_id: ticketQuery.id,
      name: ticketQuery.name,
      cancellationPolicy: ticketQuery.cancellation_policy || undefined,
      description: ticketQuery.description,
      validUntil:
        ticketQuery?.periods.find((p) => p.name === "validOn")?.end_date ||
        undefined,
      exclusions: ticketQuery?.periods
        .filter((p) => p.name === "exclusion")
        ?.map((p) => ({
          date: p.start_date,
        })),
      availability: availability,
      ticketType:
        ticketQuery?.tickets?.map((t) => ({
          capacity: (t.capacity || 100)?.toString(),
          category: t.category,
          id: t.id,
          isFree: Number.parseFloat(t.default_price) === 0,
          max_age: t.max_age,
          min_age: t.min_age,
          sellPrice: Number.parseFloat(t.default_price),
        })) || [],
    });
  }, [ticketQuery]);

  const [isFinishAndExit, setIsFinishAndExit] = useState(false);
  const { data: getAttraction } = apiSlice.useGetAttractionQuery(
    attractionId ?? ""
  );

  const comissionMultiplier: Record<
    Exclude<AttractionDetails["listing_package"], "EVENT">,
    number
  > = {
    BASIC: 0,
    ENHANCED: 0.82,
    PREMIUM: 0.88,
    STANDARD: 0.76,
  };
  const commission =
    comissionMultiplier[
      (getAttraction?.result?.listing_package as Exclude<
        AttractionDetails["listing_package"],
        "EVENT"
      >) || "BASIC"
    ];

  const dispatch = useAppDispatch();
  const { toastNotification } = useAppSelector((state) => state.global);
  const onSubmit = () => {
    const fn: SubmitHandler<EditTicketFormType> = async (e) => {
      if (isEditing) {
        await updateTicket(e as any)
          .unwrap()
          .then((res) => {
            dispatch(
              globalActions.setToastNotifcation({
                ...toastNotification,
                type: "SUCCESS",
                message: "Success",
                attractionApprovalStatus: "",
                attractionImage: "",
                attractionName: "",
                attractionAddress: "",
              })
            );
            restartAnimation();
            navigate(`/attractions/${attractionId}/ticketing/manage`);
          })
          .catch((err) => {
            dispatch(
              globalActions.setToastNotifcation({
                ...toastNotification,
                type: "ERROR",
                message: "Something went wrong, Please try again.",
                attractionApprovalStatus: "",
                attractionImage: "",
                attractionName: "",
                attractionAddress: "",
              })
            );
            restartAnimation();
          });
      } else {
        await createNewProduct({
          ...e,
          venue_id: getAttraction?.result?.venue_id,
          exclusions: e.exclusions.filter((d) => d.date),
        } as any)
          .unwrap()
          .then((res) => {
            dispatch(
              globalActions.setToastNotifcation({
                ...toastNotification,
                type: "SUCCESS",
                message: "Success",
                attractionApprovalStatus: "",
                attractionImage: "",
                attractionName: "",
                attractionAddress: "",
              })
            );
            restartAnimation();
            if (isFinishAndExit) {
              navigate(`/attractions/${attractionId}/ticketing/manage`);
            } else {
              navigate(
                `/attractions/${attractionId}/ticketing/create/${res.id}?new=true`
              );
            }

            setAllDoneModal(true);
          })
          .catch((err) => {
            dispatch(
              globalActions.setToastNotifcation({
                ...toastNotification,
                type: "ERROR",
                message: "Something went wrong, Please try again.",
                attractionApprovalStatus: "",
                attractionImage: "",
                attractionName: "",
                attractionAddress: "",
              })
            );
            restartAnimation();
          });

        setIsFinishAndExit(false);
      }
    };

    return fn;
  };

  const watchedValidUntil = useWatch({
    control: formMethods.control,
    name: "validUntil",
  });
  const {
    append: appendExclusion,
    fields: exclusions,
    remove: removeExclusion,
  } = useFieldArray({
    control: formMethods.control,
    name: "exclusions",
  });
  const {
    append: appendTicketType,
    fields: ticketTypes,
    remove: removeTicketType,
    update: updateTicketType,
  } = useFieldArray({
    control: formMethods.control,
    name: "ticketType",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log(formMethods.formState.errors)
  }, [formMethods.formState.errors])

  useEffect(() => {
    if (!attractionId || attractionId === 'undefined') {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionId, navigate])

  useEffect(() => {
    if (
      ticketError &&
      (ticketError as unknown as { status: number })?.status === 401
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [ticketError, navigate]);

  return (
    <>
      <ModalWrapper
        className="w-[483px] px-10 xl:px-0 py-0"
        open={allDoneModal}
        setOpen={setAllDoneModal}
        hideCloseButton={true}
        customContainerClassName="flex flex-col items-center w-full p-[30px] bg-white rounded-lg overflow-y-auto"
      >
        <div>
          <h3 className="text-[22px] font-bold text-black">All Done!</h3>
          <p className="mt-5 text-[#212121] text-sm font-normal">
            We will now review all the information you have submitted. Once
            approved, usually within 24 hours, your tickets will be live on our
            website. We’ll let you know when approved.
          </p>

          <div className="flex flex-row justify-end mt-5">
            <PrimaryButton
              scale="sm"
              className="w-[151px]"
              onClick={() => {
                setAllDoneModal(false);
                navigate(`/attractions/${attractionId}/ticketing/manage`);
              }}
            >
              OK
            </PrimaryButton>
          </div>
        </div>
      </ModalWrapper>
      <div className="flex flex-col flex-1 bg-[#F5F5FA]">
        <DashboardHeaderContainer>
          <h1 className="font-extrabold text-xl text-black font-roboto-flex ">
            Ticketing
          </h1>
        </DashboardHeaderContainer>
        <DashboardContainer>
          <section
            className={`flex flex-col px-6 pt-4 pb-5 bg-white xl:pt-6 xl:rounded-lg xl:mx-[70px] mb-10`}
          >
            <FormProvider {...formMethods}>
              <form
                onSubmit={formMethods.handleSubmit(onSubmit())}
                className="grid grid-cols-1 gap-5"
              >
                <legend className="text-[22px] font-extrabold text-[#212121]">
                  🎟️ {isEditing ? "Edit Ticket" : "New Ticket"}
                </legend>
                <TicketTitle />
                <div className="max-w-[600px]">
                  <Label
                    forText="validUntil"
                    title="Date On Sale Until"
                    desc="Select a date this ticket is on sale until. You will be sent a reminder a few days before the tickets go off sale, in case you want to update the date."
                  />

                  <input
                    className={`p-2 border-cs-1 w-[135px] text-cs-gray text-sm rounded-lg mt-2 h-10 ${
                      formMethods.formState.errors.validUntil
                        ? "border-cs-red"
                        : "outline-cs-pink border-[#CED3CF]"
                    }`}
                    value={moment(`${watchedValidUntil}`).format("YYYY-MM-DD")}
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    {...formMethods.register(`validUntil`)}
                    placeholder="DD-MM-YYYY"
                    max="9999-12-31"
                  />
                </div>

                <div>
                  <Label forText="availability" title="Valid On" desc="" />
                  <TicketAvailability />

                  {formMethods.formState.errors['availability'] && <ErrorMessage>
                    {formMethods.formState.errors['availability'].message}
                    </ErrorMessage>}
                </div>

                <div className="max-w-[100vw]">
                  <Label
                    forText="products"
                    title="Ticket Type"
                    desc="Here is were you can specify different entry prices, availability and inventory."
                    required={true}
                  />

                  <table className="mt-2.5 relative">
                    <thead className="text-sm font-bold">
                      <tr>
                        <th className="py-2.5 px-[15px] border border-[#F1F1F1]">
                          Ticket Type
                        </th>
                        <th className="py-2.5 px-[15px] border border-[#F1F1F1]">
                          Min Age
                        </th>
                        <th className="py-2.5 px-[15px] border border-[#F1F1F1]">
                          Max Age
                        </th>
                        <th className="py-2.5 px-[15px] border border-[#F1F1F1]">
                          Daily Inventory
                        </th>
                        <th className="py-2.5 px-[15px] border border-[#F1F1F1]">
                          Sell Price
                        </th>
                        <th className="py-2.5 px-[15px] border border-[#F1F1F1]">
                          You’ll Receive
                        </th>
                        <th className="py-2.5 px-[15px] border border-[#F1F1F1]">
                          Free Entry?
                        </th>
                        <th className="py-2.5 px-[15px] border border-[#F1F1F1]"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticketTypes.map((ticketType, i) => (
                        <tr key={i}>
                          <td className="border border-[#F1F1F1] text-sm font-normal text-center py-2.5 px-[15px]">
                            <TicketCategory index={i} update={({min_age, max_age, category}: {min_age: number, max_age: number, category: string}) => {
                              updateTicketType(i, {
                                ...ticketType,
                                category,
                                min_age: min_age,
                                max_age: max_age
                              })
                            }} />
                          </td>
                          <td className="border border-[#F1F1F1] text-sm font-normal text-center py-2.5 px-[15px]">
                            <TicketMinAge index={i} />
                          </td>
                          <td className="border border-[#F1F1F1] text-sm font-normal text-center py-2.5 px-[15px]">
                            <TicketMaxAge index={i} />
                          </td>
                          <td className="border border-[#F1F1F1] text-sm font-normal text-center py-2.5 px-[15px]">
                            <TicketCapacity index={i} />
                          </td>
                          <td className="border border-[#F1F1F1] text-sm font-normal text-center py-2.5 px-[15px]">
                            <div className="w-[81px] relative ">
                            <div className="absolute top-[6px] left-[10px] z-20 pointer-events-none">£</div>
                              <TextInput
                                name={`ticketType.${i}.sellPrice`}
                                className=""
                                labelClassName="group-focus-within:text-cs-pink text-sm font-normal"
                                inputClassName="flex flex-col w-[81px] h-[31px] flex justify-center items-center text-center rounded-full bg-[#F1F1F1]"
                                label=""
                                control={formMethods.control}
                                required
                                inputProps={{
                                  ...formMethods.register(
                                    `ticketType.${i}.sellPrice`,
                                    {
                                      setValueAs(val) {
                                        const float = parseFloat(val);
                                        if (Number.isNaN(float)) return val;

                                        if (float === 0) {
                                          formMethods.setValue(`ticketType.${i}.isFree`, true)
                                        } else {
                                          formMethods.setValue(`ticketType.${i}.isFree`, false)
                                        }
                                        return float;
                                      },
                                    }
                                  ),
                                  type: 'text',
                                }}
                              />
                            </div>
                          </td>
                          <td className="border border-[#F1F1F1] text-sm font-normal text-center py-2.5 px-[15px]">
                            £
                            {((formMethods.watch(`ticketType.${i}.sellPrice`,ticketType.sellPrice) * commission).toFixed(2))}
                          </td>
                          <td className="border border-[#F1F1F1] text-sm font-normal text-center py-2.5 px-[15px]">
                            <Controller
                              control={formMethods.control}
                              name={`ticketType.${i}.isFree`}
                              render={({ field: { value, onChange } }) => (
                                <label className="checkbox-item !w-full !md:w-[723px] primary-checkmark items-center flex">
                                  <input
                                    className="!w-full !md:w-[723px] !h-5"
                                    type="checkbox"
                                    checked={value}
                                    onChange={(newValue) => {
                                      if (newValue.target.checked) {
                                        formMethods.setValue(`ticketType.${i}.sellPrice`, 0)
                                      } else {
                                        formMethods.setValue(`ticketType.${i}.sellPrice`, 24.5)
                                      }
                                      onChange(newValue.target.checked);
                                    }}
                                    readOnly
                                  />
                                  <span className="checkmark border-cs-bright-lt-gray mx-auto inset-x-0"></span>
                                </label>
                              )}
                            />
                          </td>
                          <td className="border border-[#F1F1F1] text-sm font-normal text-center py-2.5 px-[15px]">
                           {i !== 0 ? <button
                              type="button"
                              onClick={() => removeTicketType(i)}
                            >
                              <svg
                                width="20"
                                height="23"
                                viewBox="0 0 20 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 6.16992H19M6.25 6.16992V5.41992C6.25 4.42536 6.64509 3.47153 7.34835 2.76827C8.05161 2.06501 9.00544 1.66992 10 1.66992C10.9946 1.66992 11.9484 2.06501 12.6517 2.76827C13.3549 3.47153 13.75 4.42536 13.75 5.41992V6.16992M7.75 10.6714V16.6744M12.25 10.6714V16.6744M3.25 6.16992H16.75V19.6699C16.75 20.0677 16.592 20.4493 16.3107 20.7306C16.0294 21.0119 15.6478 21.1699 15.25 21.1699H4.75C4.35218 21.1699 3.97064 21.0119 3.68934 20.7306C3.40804 20.4493 3.25 20.0677 3.25 19.6699V6.16992Z"
                                  stroke="#212121"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button> : <div className="w-[18px]"></div>}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <AddButton
                    className="mt-2"
                    onClick={() =>
                      appendTicketType({
                        category: "adult",
                        isFree: false,
                        min_age: 1,
                        max_age: 12,
                        sellPrice: 0,
                        capacity: "100",
                      })
                    }
                  >
                    Add New Ticket Type
                  </AddButton>
                </div>

                <div className="max-w-[598px]">
                  <Label
                    forText="exclusions"
                    title="Exclusions"
                    desc="Select a date when the above ticket is not available i.e attraction closed on Christmas Day."
                  />

                  {exclusions.map((exclusion, index) => (
                    <ExtraDateInput
                      index={index}
                      key={exclusion.id}
                      onRemove={() => removeExclusion(index)}
                    />
                  ))}
                  {formMethods.formState.errors["exclusions"] && (
                    <ErrorMessage>Required</ErrorMessage>
                  )}
                  <AddButton
                    className="mt-2"
                    onClick={() => appendExclusion({ date: "" })}
                  >
                    Add Extra Date
                  </AddButton>
                </div>

                <div>
                  <div className="max-w-[596px]">
                    <Label
                      forText="exclusions"
                      title="Cancellation Policy"
                      desc="This cancellation policy will be visible to customers prior to booking. DOWTK will manage requests for cancellation inline with your policy."
                      required={true}
                    />
                  </div>

                  <Controller
                    control={formMethods.control}
                    name="cancellationPolicy"
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <>
                        <Select
                          onChange={(value, meta) => {
                            onChange(value?.value ?? null);
                          }}
                          value={cancellationOptions.find(
                            (v) => v.value === value
                          )}
                          defaultValue={null}
                          isClearable={false}
                          closeMenuOnSelect={false}
                          menuIsOpen={true}
                          hideSelectedOptions={false}
                          options={cancellationOptions}
                          components={{ Option: MultiSelectCategoryOptionsSm }}
                          placeholder={""}
                          styles={{
                            multiValue: (styles) => ({
                              ...styles,
                              backgroundColor: "#6836D1",
                              borderRadius: 20,
                              height: 40,
                              display: "flex",
                              alignItems: "center",
                            }),
                            multiValueRemove: () => ({
                              display: "none",
                            }),
                            multiValueLabel: (styles) => ({
                              ...styles,
                              color: "#FFF",
                              paddingLeft: 10,
                              paddingRight: 10,
                              fontSize: 14,
                              fontWeight: 400,
                            }),
                            menu: (styles) => ({
                              ...styles,
                              margin: 0,
                              paddingLeft: 5,
                              border: "0",
                              overflow: "visible",
                              boxShadow: "none",
                              display: "flex",
                              width: "100%",
                              position: "relative",
                            }),
                            menuList: (base) => ({
                              ...base,
                              marginTop: 8,
                              marginBottom: 0,
                              display: "flex",
                              gap: `30px 12px`,
                              overflow: "visible",
                              flexWrap: "wrap",
                            }),
                            option: (base) => ({
                              ...base,
                              background: "red",
                              padding: "0",
                            }),
                            noOptionsMessage: (base) => ({
                              ...base,
                              display: "none",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              display: "none",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            background: "none",
                            colors: {
                              ...theme.colors,
                              neutral80: "#555",
                            },
                          })}
                        />
                        {error && <ErrorMessage>{error.message}</ErrorMessage>}
                      </>
                    )}
                  />
                </div>

                <div className="max-w-[598px]">
                  <Label
                    forText="description"
                    title="Ticket Information & Conditions"
                    desc="Include information here which is important for customer to know before they arrive."
                  />

                  <textarea
                    {...formMethods.control.register("description")}
                    placeholder={`You might include info here about children under 12 must be accompanied by a paying adult over 18.\n\nOr information on a meeting point or parking suggestions.`}
                    className="w-full max-w-[551px] h-40 py-2.5 px-5 mt-3 rounded-lg text-cs-gray text-md border-[#CFDBD5] border-cs-1 outline-cs-pink placeholder:text-sm placeholder:text-[#CFDBD5] placeholder:font-normal placeholder:leading-[21px]"
                  ></textarea>
                </div>

                <div>
                  <div className="h-[1px] bg-[#D9D9D9] w-full mb-5"></div>
                  {isEditing ? (
                    <div className="flex items-center gap-2.5 justify-end w-full">
                      <PrimaryButton
                        scale="sm"
                        type="submit"
                        className={`px-4`}
                        loading={isLoading}
                      >
                        Save changes
                      </PrimaryButton>
                    </div>
                  ) : (
                    <div className="flex items-center gap-2.5 justify-end w-full">
                      <OutlineButton
                        className="h-9 text-sm px-2.5 leading-[16px]"
                        scale="none"
                        type="button"
                        onClick={async () => {
                          setIsFinishAndExit(true);
                          await formMethods.handleSubmit(onSubmit())();
                        }}
                      >
                        Save & Exit
                      </OutlineButton>
                      <PrimaryButton
                        scale="sm"
                        type="submit"
                        className={`px-4`}
                        loading={isLoading}
                      >
                        Finish & Submit
                      </PrimaryButton>
                    </div>
                  )}
                  {!formMethods.formState.isValid &&
                    formMethods.formState.isSubmitted && (
                      <ErrorMessage>
                        Please fix the errors indicated above to continue
                      </ErrorMessage>
                    )}
                </div>
              </form>
            </FormProvider>
          </section>
        </DashboardContainer>
        <Footer />
      </div>
    </>
  );
};

export default CreateTicket;

export const getCategory = (value: string) => {
  return categories.find((c) => c.value === value);
};
export const getAge = (
  value: string,
  additional: { label: string; value: string }[] = []
) => {
  return [...additional, ...ages].find((c) => c.value === value);
};
export const defaultPrice = {
  category: "3_and_under",
  age: {
    from: "0",
    to: "3",
  },
  price: null,
};

export function Label({
  forText,
  title,
  desc,
  required = false,
}: {
  forText: string;
  title: string;
  desc: string;
  required?: boolean;
}) {
  return (
    <div>
      <label htmlFor={forText} className="text-sm font-bold text-[#212121]">
        {title} {required && <span className="text-cs-pink">*</span>}
      </label>
      <p className="text-[#5F646D] text-sm font-normal my-2.5">{desc}</p>
    </div>
  );
}

function AddButton({
  children,
  className = "",
  onClick,
}: {
  children: ReactNode;
  className?: string;
  onClick?: Function;
}) {
  return (
    <button
      type="button"
      className={`text-[#5F646D] text-sm font-normal flex items-center gap-[5px] ${className}`}
      onClick={() => onClick?.()}
    >
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9377 9.05428H8.76058V12.2314C8.76058 12.3999 8.69363 12.5615 8.57447 12.6807C8.45531 12.7998 8.29369 12.8668 8.12516 12.8668C7.95664 12.8668 7.79502 12.7998 7.67586 12.6807C7.55669 12.5615 7.48975 12.3999 7.48975 12.2314V9.05428H4.31266C4.14414 9.05428 3.98252 8.98734 3.86336 8.86817C3.74419 8.74901 3.67725 8.58739 3.67725 8.41886C3.67725 8.25034 3.74419 8.08872 3.86336 7.96956C3.98252 7.85039 4.14414 7.78345 4.31266 7.78345H7.48975V4.60636C7.48975 4.43784 7.55669 4.27622 7.67586 4.15706C7.79502 4.03789 7.95664 3.97095 8.12516 3.97095C8.29369 3.97095 8.45531 4.03789 8.57447 4.15706C8.69363 4.27622 8.76058 4.43784 8.76058 4.60636V7.78345H11.9377C12.1062 7.78345 12.2678 7.85039 12.387 7.96956C12.5061 8.08872 12.5731 8.25034 12.5731 8.41886C12.5731 8.58739 12.5061 8.74901 12.387 8.86817C12.2678 8.98734 12.1062 9.05428 11.9377 9.05428Z"
          fill="#5F646D"
        />
      </svg>
      {children}
    </button>
  );
}

function ExtraDateInput({
  index,
  onRemove,
}: {
  index: number;
  onRemove: Function;
}) {
  const { control } = useFormContext();
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Controller
      control={control}
      name={`exclusions.${index}.date`}
      render={({ field, fieldState: { invalid } }) => (
        <div className="flex items-center gap-2.5">
          <input
            ref={inputRef}
            className={`p-2 border-cs-1 w-[130px] text-cs-gray text-sm rounded-lg mt-2 h-10 ${
              invalid ? "border-cs-red" : "outline-cs-pink border-[#CED3CF]"
            }`}
            value={moment(`${field.value}`).format("YYYY-MM-DD")}
            type="date"
            min={new Date().toISOString().split("T")[0]}
            placeholder="DD-MM-YYYY"
            max="9999-12-31"
            onChange={field.onChange}
          />
          <button
            type="button"
            className="text-cs-pink text-sm font-normal"
            onClick={() => inputRef.current?.showPicker()}
          >
            Change date
          </button>
          {index !== 0 && (
            <button
              type="button"
              className="w-4 h-4"
              onClick={() => onRemove()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>
      )}
    />
  );
}
