import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import React, { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { apiSlice } from "../../../store/apiSlice";
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
  Column,
  useGlobalFilter,
  useSortBy,
  UseSortByInstanceProps,
  CellProps,
} from "react-table";
import Table from "../../../lib/react-table/Table";
import moment from "moment";
import * as Diff from "diff";
import {
  daysOfTheWeekMapping,
  faqExtraNesting,
  faqPropertyDictionary,
  faqQuestionsDict,
  pricingCategoryMapping,
  timesOfDayMapping,
} from "../../../helpers/dictionary";
import {
  SingleAttractionTemplate,
  className,
} from "../../../components/global/SingleAttractionTemplate";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import { SubmitHandler } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../store";
import { globalActions } from "../../../store/globalSlice";
import { walkthroughActions } from "../../../store/walkthroughSlice";

interface IAttractionDetails {
  showPublishingButtons: boolean;
}

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const TypeTEXT = [
  "name",
  "summary",
  "description_html",
  "pricing_html",
  "opening_html",
  "highlight_1",
  "highlight_2",
  "highlight_3",
  "highlight_1_description",
  "highlight_2_description",
  "highlight_3_description",
  "location_html",
];

const HTMLs = [
  "description_html",
  "pricing_html",
  "opening_html",
  "location_html",
  "highlight_1_description",
  "highlight_2_description",
  "highlight_3_description",
];

const propsJSON = [
  "busiest_times_json",
  "opening_times_json",
  "faqs_json",
  "pricing_json",
];

const propsMedia = [
  "media_1_id",
  "media_2_id",
  "media_3_id",
  "media_4_id",
  "media_5_id",
  "media_6_id",
  "media_7_id",
  "media_8_id",
  "media_9_id",
  "media_10_id",
  "media_11_id",
  "media_12_id",
  "primary_image",
];

const cleanAndConvertToArray = (item: string) => {
  item = item.replaceAll("{", "");
  item = item.replaceAll("}", "");
  item = item.replaceAll('"', "");
  return item.split(",");
};

const getOpeningTimesString = (openingTimes: OpeningTimes[]) => {
  let openingTimesString = "Set to open";
  for (let i = 0; i < openingTimes.length; i++) {
    const time = openingTimes[i];
    //If first set to alwaysOpen, jump out of loop
    if (time.alwaysOpen && i == 0) {
      //&& (prevInfo?.data.times ? !prevInfo.data.times[0].alwaysOpen : true)
      openingTimesString = `${openingTimesString} 24 hours.`;
      break;
    }

    openingTimesString = `${openingTimesString}${i > 0 ? " &" : ""} from ${
      time.opensAt
    } to ${time.closesAt}`;
  }

  return openingTimesString;
};

const summarizeOpeningTimesJsonChanges = (
  oldValue: VenueOpeningTimesJson | null,
  newValue: VenueOpeningTimesJson | null
) => {
  if (newValue == null) {
    return <>Opening times set to null</>;
  }

  const jsxElements = [];

  if (oldValue) {
    if (
      oldValue.showOpeningTimes == true &&
      newValue.showOpeningTimes == false
    ) {
      jsxElements.push(
        <li className="ml-4">Changed opening times to hidden.</li>
      );
      return;
    }
    if (
      oldValue.showOpeningTimes == false &&
      newValue.showOpeningTimes == true
    ) {
      jsxElements.push(
        <li className="ml-4">Changed opening times to visible.</li>
      );
    }
  }

  newValue?.openingTimes?.weekly.map((newInfo) => {
    const label = newInfo.day;

    const prevInfo =
      oldValue && oldValue.openingTimes
        ? oldValue.openingTimes.weekly.find((_info) => _info.day == newInfo.day)
        : null;

    const changes = [];

    const isClosedChanged = prevInfo
      ? prevInfo.data.isClosed != newInfo.data.isClosed
      : true;
    if (isClosedChanged) {
      changes.push(
        <li>
          {newInfo.data.isClosed ? "Changed to closed." : "Changed to open."}
        </li>
      );
    }

    const prevInfoString = JSON.stringify(prevInfo?.data.times ?? []);
    const newInfoString = JSON.stringify(newInfo.data.times ?? []);
    const differenceInTimes = prevInfoString != newInfoString;

    if (differenceInTimes) {
      const openingTimesString = getOpeningTimesString(
        newInfo.data.times ?? []
      );
      changes.push(<li>{openingTimesString}</li>);
    } else {
    }

    if (changes.length > 0) {
      jsxElements.push(
        <div className={`${jsxElements.length > 0 ? "mt-2" : ""}`}>
          <div>{label} opening times updated:</div>
          <ul className="[&>*]:ml-4 list-disc">{changes}</ul>
        </div>
      );
    } else {
    }
  });

  if (
    JSON.stringify(newValue.openingTimes?.special ?? []) !=
    JSON.stringify(oldValue?.openingTimes?.special ?? [])
  ) {
    const specialDays = (newValue.openingTimes.special ?? []).map(
      (specialDay) => {
        return (
          <li>
            {specialDay.date} -{" "}
            {specialDay.isClosed
              ? "Closed"
              : `${getOpeningTimesString(specialDay.times ?? [])}`}
          </li>
        );
      }
    );

    jsxElements.push(
      <div className={`${jsxElements.length > 0 ? "mt-2" : ""}`}>
        <div>Special times updated:</div>
        <ul className="[&>*]:ml-4 list-disc">{specialDays}</ul>
      </div>
    );
  }
  return jsxElements;
};

const summarizeFAQJSONChanges = (
  oldValue: VenueFaqJson | null,
  newValue: VenueFaqJson | null
) => {
  if (!newValue) {
    return <>FAQs unset.</>;
  }

  const jsxElements = [];

  for (const prop in newValue) {
    if (newValue?.hasOwnProperty(prop)) {
      const prevProp = oldValue
        ? JSON.stringify((oldValue as any)[prop])
        : oldValue;
      const stringifiedProp = JSON.stringify((newValue as any)[prop]);

      const prevValueItem = oldValue ? (oldValue as any)[prop] : oldValue;
      const newValueItem = (newValue as any)[prop];

      const label = faqPropertyDictionary[prop] ?? prop;
      const faqItemChanges = [];

      if (prevProp != stringifiedProp) {
        //Change has happened here
        if (prop == "extraFaqs") {
          const differenceInExtras = Diff.diffArrays<VenueFaq, VenueFaq>(
            prevValueItem ?? [],
            newValueItem
          )
            .filter((d) => d.added == true)
            .reduce((prev, curr, i) => {
              return [...prev, ...curr.value];
            }, [] as VenueFaq[]);

          for (let i = 0; i < differenceInExtras.length; i++) {
            const extraDiff = differenceInExtras[i];
            faqItemChanges.push(
              <li>
                {extraDiff.title} -{" "}
                {removeHtmlTags(extraDiff?.extraNotes ?? "")}
              </li>
            );
          }
        } else {
          for (const childProp in (newValue as any)[prop]) {
            const childValue = (newValue as any)[prop][childProp];
            const prevChildValue = oldValue
              ? (oldValue as any)?.[prop]?.[childProp]
              : oldValue;
            if (
              JSON.stringify(prevChildValue ?? "") !==
              JSON.stringify(childValue ?? "")
            ) {
              // Summarize this change, if null default to empty string to standardise
              const childLabel =
                (faqQuestionsDict as any)[prop][childProp] ?? childProp;
              if (childProp === "extraNotes") {
                faqItemChanges.push(
                  <li>
                    Notes updated:{" "}
                    {generateHTMLDiffSummary(prevChildValue, childValue)}
                  </li>
                );
              } else {
                faqItemChanges.push(
                  <li>
                    <span>{childLabel} set to</span>:{" "}
                    {mapFaqQuestionsToHumanReadable(
                      JSON.stringify(childValue),
                      prop,
                      childProp
                    )}
                  </li>
                );
              }
            }
          }
        }
      }

      if (faqItemChanges.length > 0) {
        jsxElements.push(
          <div className={`${jsxElements.length > 0 ? "mt-2" : ""}`}>
            <div>{label} changed:</div>
            <ul className="[&>*]:ml-4 list-disc">{faqItemChanges}</ul>
          </div>
        );
      }
    }
  }

  return jsxElements;
};

const summarizePricingJson = (
  oldValue: VenuePricingJson | null,
  newValue: VenuePricingJson | null
) => {
  if (!newValue) {
    return <>Pricing unset.</>;
  }

  //const difference = Diff.diffArrays(oldValue ?? [], newValue ?? []).filter((d) => d.added)
  return (
    <div>
      <div>Pricing set to:</div>
      <ul className="[&>*]:ml-4 list-disc">
        {(typeof newValue === "string"
          ? JSON.parse(newValue as any)
          : newValue
        ).map((v: VenuePricingItem) => (
          <li>
            {pricingCategoryMapping[v.category]} ({v.age.from} - {v.age.to}): £
            {v.price}
          </li>
        ))}
      </ul>
    </div>
  );
};

const getBusiestTimesString = (times: timeOfDay | null) => {
  let busiestTimes = times ? "" : null;
  for (const childProp in times) {
    const childPropItem = (times as any)[childProp];
    if (childPropItem == true) {
      busiestTimes = `${busiestTimes}${
        busiestTimes != null && busiestTimes.length > 0 ? ", " : ""
      }${(timesOfDayMapping as any)[childProp]}`;
    }
  }
  busiestTimes =
    busiestTimes == null
      ? null
      : busiestTimes.length > 0
      ? busiestTimes
      : "unset";

  return busiestTimes;
};

const summarizeBusiestTimesJson = (
  oldValue: VenueBusiestTimesJson | null,
  newValue: VenueBusiestTimesJson | null
) => {
  if (!newValue) {
    return <>Busiest times unset.</>;
  }

  const jsxElements = [];

  for (const prop in newValue) {
    const newValueItem = (newValue as any)[prop];
    const oldValueItem = oldValue ? (oldValue as any)[prop] : oldValue;

    if (JSON.stringify(newValueItem) != JSON.stringify(oldValueItem ?? {})) {
      let oldBusiestTime = getBusiestTimesString(oldValueItem);

      let newBusiestTime = getBusiestTimesString(newValueItem);

      jsxElements.push(
        <li>
          {(daysOfTheWeekMapping as any)[prop]} busiest time set
          {oldBusiestTime ? ` from ${oldBusiestTime}` : ""} to {newBusiestTime}
        </li>
      );
    }
  }

  return (
    <div>
      <div>Busiest times updated</div>
      <ul className="[&>*]:ml-4 list-disc">{jsxElements}</ul>
    </div>
  );
};

const generateHTMLDiffSummary = (previous_value: any, new_value: any) => {
  const prev = removeHtmlTags(previous_value ?? "");
  const after = removeHtmlTags(new_value ?? "");

  const diff = shortenDiffChanges(
    Diff.diffWords(prev, after) as {
      added: boolean;
      removed: boolean;
      value: string;
    }[]
  );
  return diff.map((d) => (
    <span
      className={`${
        d.removed
          ? "text-red-500 line-through"
          : d.added
          ? "text-green-600 font-semibold"
          : "text-cs-gray"
      }`}
    >
      {d.value}
    </span>
  ));
};

const AttracionChangeLog: React.FC<IAttractionDetails> = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [getQueryPageSize, setQueryPageSize] = useState<string>("25");
  const [getQueryPage, setQueryPage] = useState<string>(
    searchParams.get("page")
      ? parseInt(searchParams?.get("page") ?? "0").toString()
      : "0"
  );
  const [getQuerySortBy, setQuerySortBy] = useState<string>("changed_at");
  const [getQuerySortDesc, setQuerySortDesc] = useState<string>("true");

  const { attractionId } = useParams();
  const { data: getAttraction, error: attractionError } = apiSlice.useGetAttractionQuery(
    attractionId ?? ""
  );
  const { result: attractionResult } = getAttraction ?? {};

  const { data: tableData, isFetching, error: changeLogError } =
    apiSlice.useGetAttractionChangeLogQuery({
      id: location.pathname.split("/")[2],
      pageSize: getQueryPageSize,
      page: getQueryPage,
      sortBy: getQuerySortBy,
      sortDesc: getQuerySortDesc,
    });

  const data = React.useMemo<AttractionChangeLog[]>(
    () => tableData?.results ?? ([] as AttractionChangeLog[]),
    [tableData]
  );
  const columns = React.useMemo<Column<AttractionChangeLog>[]>(
    () => [
      {
        Header: "Date / Time",
        accessor: "changed_at",
        sortType: (a: any, b: any) => {
          const previousRow = moment(a.original.changed_at).toISOString();
          const nextRow = moment(b.original.changed_at).toISOString();

          if (previousRow < nextRow) {
            return 1;
          } else {
            return -1;
          }
        },
        Cell: ({ row: { original } }: CellProps<AttractionChangeLog>) => {
          return (
            <>
              {moment(original.changed_at, "YYYY/MM/DD HH:mm:ss").format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            </>
          );
        },
        width: "15%",
      },
      {
        Header: "User",
        accessor: (data) => {
          return (
            <>
              <div className="flex items-center gap-2">
                <div className="xl:hidden block font-bold text-sm">User:</div>
                {data.changed_by_user.first_name}{" "}
                {data.changed_by_user.last_name}
              </div>
            </>
          );
        },
        width: "15%",
      },
      {
        Header: "Section",
        accessor: (data) => {
          return (
            <>
              <div className="flex items-center gap-2">
                <div className="xl:hidden block font-bold text-sm">
                  Section:
                </div>
                {data.section == data.label
                  ? data.section
                  : `${data.section} - ${data.label}`}
              </div>
            </>
          );
        },
        sortType: (a: any, b: any) => {
          return a.original.section > b.original.section ? 1 : -1;
        },
        width: "15%",
      },
      //   {
      //     Header: "Previous Value",
      //     accessor: "previous_value",
      //     width: "15%",
      //     disableSortBy: true,
      // },
      {
        Header: "Old / New Value",
        accessor: (data) => {
          let previousValue = data.previous_value;
          let newValue = data.new_value;

          if (data.key == "published") {
            return (
              <>
                {(data.new_value ? JSON.parse(data.new_value) : false) == true
                  ? "Published"
                  : "Unpublished"}
              </>
            );
          }

          if (data.key == "opening_times_json") {
            return (
              <>
                <span className="xl:hidden inline font-bold text-sm mr-2">
                  Old/New Value:
                </span>
                {summarizeOpeningTimesJsonChanges(
                  previousValue && typeof previousValue === "string"
                    ? JSON.parse(previousValue)
                    : previousValue,
                  newValue && typeof newValue === "string"
                    ? JSON.parse(newValue)
                    : newValue
                )}
              </>
            );
          }

          if (data.key == "faqs_json") {
            return (
              <>
                <span className="xl:hidden inline font-bold text-sm mr-2">
                  Old/New Value:
                </span>
                {summarizeFAQJSONChanges(
                  previousValue && typeof previousValue === "string"
                    ? JSON.parse(previousValue)
                    : previousValue,
                  newValue && typeof newValue === "string"
                    ? JSON.parse(newValue)
                    : newValue
                )}
              </>
            );
          }

          if (data.key == "busiest_times_json") {
            return (
              <>
                <span className="xl:hidden inline font-bold text-sm mr-2">
                  Old/New Value:
                </span>

                {summarizeBusiestTimesJson(
                  previousValue && typeof previousValue === "string"
                    ? JSON.parse(previousValue)
                    : previousValue,
                  newValue && typeof newValue === "string"
                    ? JSON.parse(newValue)
                    : newValue
                )}
              </>
            );
          }

          if (data.key == "pricing_json") {
            return (
              <>
                <span className="xl:hidden inline font-bold text-sm mr-2">
                  Old/New Value:
                </span>
                {summarizePricingJson(
                  previousValue && typeof previousValue === "string"
                    ? JSON.parse(previousValue)
                    : previousValue,
                  newValue && typeof newValue === "string"
                    ? JSON.parse(newValue)
                    : newValue
                )}
              </>
            );
          }

          if (data.key == "facilities") {
            const newClean = data.new_value
              ? cleanAndConvertToArray(data.new_value)
              : [];
            const prevClean = data.previous_value
              ? cleanAndConvertToArray(data.previous_value)
              : [];

            const diffArray = Diff.diffArrays(prevClean, newClean);
            const added = diffArray
              .filter((d) => d.added == true)
              .reduce((prev, curr, i) => {
                return [...prev, ...curr.value];
              }, [] as string[]);
            const removed = diffArray
              .filter((d) => d.removed == true)
              .reduce((prev, curr, i) => {
                return [...prev, ...curr.value];
              }, [] as string[]);

            return (
              <>
                <span className="xl:hidden inline font-bold text-sm mr-2">
                  Old/New Value:
                </span>
                {added.length > 0 && (
                  <span className="mr-2">Added {added.toString()}</span>
                )}
                {removed.length > 0 && <>Removed {removed.toString()}</>}
              </>
            );
          }

          if (data.key == "location") {
            let val;
            try {
              val = data.new_value
                ? typeof data.new_value == "string"
                  ? JSON.parse(data.new_value)
                  : data.new_value
                : null;
            } catch (e) {
              //Left over test data that set location as geom instead of JSON, causes an error if attempted to be parsed
              console.log("Cannot parse", data.new_value, "as it's not JSON.");
              return (
                <>
                  <span className="xl:hidden inline font-bold text-sm mr-2">
                    Old/New Value:
                  </span>
                  Updated location
                </>
              );
            }

            if (val) {
              return (
                <>
                  <span className="xl:hidden inline font-bold text-sm mr-2">
                    Old/New Value:
                  </span>
                  <b>New location set to </b>
                  {val.coordinates.toString()}
                </>
              );
            } else {
              return <>Location removed.</>;
            }
          }

          if (data.key == "operator_id") {
            if (previousValue && newValue) {
              return (
                <>
                  <span className="xl:hidden inline font-bold text-sm mr-2">
                    Old/New Value:
                  </span>
                  Operator <b>{newValue}</b> has taken over ownership from{" "}
                  <b>{previousValue}</b>
                </>
              );
            }

            if (previousValue && !newValue) {
              return (
                <>
                  <span className="xl:hidden inline font-bold text-sm mr-2">
                    Old/New Value:
                  </span>
                  Operator <b>{previousValue}</b> has been revoked of ownership.
                </>
              );
            }

            if (newValue && !previousValue) {
              return (
                <>
                  <span className="xl:hidden inline font-bold text-sm mr-2">
                    Old/New Value:
                  </span>
                  Operator <b>{newValue}</b> has taken ownership
                </>
              );
            }
          }

          if (
            propsMedia.includes(data.key) &&
            !(data.new_value && data.previous_value)
          ) {
            if (data.key == "primary_image") {
              return (
                <>
                  <span className="xl:hidden inline font-bold text-sm mr-2">
                    Old/New Value:
                  </span>
                  <b>Primary image set to </b>
                  {data.new_value}
                </>
              );
            }

            if (data.new_value && !data.previous_value) {
              //Media added
              return (
                <>
                  <span className="xl:hidden inline font-bold text-sm mr-2">
                    Old/New Value:
                  </span>
                  <b>Media added: </b> {data.new_value}
                </>
              );
            }

            if (!data.new_value && data.previous_value) {
              //Media removed
              return (
                <>
                  <span className="xl:hidden inline font-bold text-sm mr-2">
                    Old/New Value:
                  </span>
                  <b>Removed media </b> {data.previous_value}
                </>
              );
            }
          }

          if (propsJSON.includes(data.key)) {
            return (
              <>
                <span className="xl:hidden inline font-bold text-sm mr-2">
                  Old/New Value:
                </span>
                {data.label} updated
              </>
            );
          }

          if (TypeTEXT.includes(data.key ?? "")) {
            /*
                const prev = HTMLs.includes(data.key ?? '') ? removeHtmlTags(data.previous_value ?? '') : data.previous_value ?? ''
                const after = HTMLs.includes(data.key ?? '') ? removeHtmlTags(data.new_value ?? '') : data.new_value ?? ''

                const diff = shortenDiffChanges(Diff.diffWords(prev, after) as { added: boolean, removed: boolean, value: string }[])
                return diff.map(d => (<span className={`${d.removed ? 'text-red-500 line-through' : d.added ? 'text-green-600 font-semibold' : 'text-cs-gray'}`}>{d.value}</span>))
                */

            return (
              <>
                <span className="xl:hidden inline font-bold text-sm mr-2">
                  Old/New Value:
                </span>
                {generateHTMLDiffSummary(data.previous_value, data.new_value)}
              </>
            );
          }

          return (
            <>
              <span className="xl:hidden inline font-bold text-sm mr-2">
                Old/New Value:
              </span>

              <div className="grid grid-cols-2">
                <div className="relative pr-4 text-sm font-normal text-cs-gray">
                  {previousValue}

                  <span className="w-[1px] bg-cs-off-white h-[calc(100%+24px)] right-0 absolute -top-3"></span>
                </div>
                <div className="pl-4 text-sm font-normal text-cs-gray">
                  {newValue}
                </div>
              </div>
            </>
          );
        },
        width: "30%",
        disableSortBy: true,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable<AttractionChangeLog>(
    {
      columns,
      data,
      manualPagination: true,
      disableSortRemove: true,
      manualSortBy: true,
      pageCount: tableData?.total,
      initialState: {
        pageIndex: parseInt(getQueryPage),
        pageSize:
          parseInt(searchParams.get("pageSize")!) || parseInt(getQueryPageSize),
        //sortBy: [{ id: "changed_at", desc: false }],
        sortBy: [
          {
            id: searchParams.get("sortBy") || getQuerySortBy,
            desc:
              searchParams.get("sortDesc") === "true" ||
              getQuerySortDesc !== "false",
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<AttractionChangeLog>;

  useEffect(() => {
    document.title = "Attraction Changelog | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (!isFetching) {
      setSearchParams({
        pageSize: pageSize.toString(),
        page: pageIndex.toString(),
        sortBy: sortBy[0].id.toLowerCase(),
        sortDesc: sortBy[0].desc!.toString(),
      });

      setQueryPageSize(pageSize.toString());
      setQueryPage(pageIndex.toString());
      setQuerySortBy(sortBy[0].id.toLowerCase());
      setQuerySortDesc(sortBy[0].desc!.toString());
    }
  }, [pageSize, pageIndex, isFetching, sortBy[0].id, sortBy[0].desc]);

  const [updateAttraction, { isError, isSuccess: isUpdateSuccess }] =
    apiSlice.useUpdateAttractionDraftMutation();
  const [isPublishing, setIsPublishing] = useState(false);
  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<{}> = async (e) => {
      setIsPublishing(publish);
      await updateAttraction({
        id: attractionId,
        publish,
        payload: {},
      });
      if (!isSilent) {
        restartAnimation();
      }
    };

    return fn;
  };

  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  // const bypassQueue = user?.role === 'ADMIN' || ['ENHANCED', 'PREMIUM'].includes(tableData?.results?.listing_package ?? '')
  const bypassQueue = false;

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: isPublishing
            ? bypassQueue
              ? "Published"
              : "Submitted for approval"
            : "Your changes have been saved",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: isPublishing
            ? "Cannot publish attraction"
            : "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  function getPayload() {
    return {};
  }

  const { state } = location;
  const { user } = useAppSelector((state) => state.global);
  const [finishedOnboarding] = apiSlice.useFinishedOnboardingMutation();
  useEffect(() => {
    if (
      state?.onboardingComplete === true &&
      attractionResult?.finished_onboarding !== true &&
      user?.role !== "ADMIN"
    ) {
      // window.scrollTo(0, 0);
      // dispatch(walkthroughActions.setWalkthroughType("newAttractionDone"));
      // dispatch(walkthroughActions.setWalkthroughStatus("startModal"));
      // dispatch(walkthroughActions.setAttractionId(attractionId ?? ""));
      (async () => {
        await finishedOnboarding(attractionId ?? "");
      })()
    }
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (!attractionError || !changeLogError) return;
    if (
      (attractionError as unknown as { status: number })?.status === 403 ||
      (changeLogError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionError, navigate, changeLogError]);

  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 404
    ) {
      navigate("/not-found", { replace: true });
    }
  }, [attractionError]);

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Edit Attraction"
        mobilePublishButtons={true}
        showPublishingButtons
        saveFn={onSubmit}
        getPayload={getPayload}
        isDirty={false}
      />

      <SingleAttractionTemplate
        isAttraction={true}
        doneWalkthrough={undefined}
        name={""}
        unstyled={true}
      >
        <div className={className}>
          <h2 className="text-[22px] font-extrabold text-black">
            ✍️ Change Log
          </h2>
          <p className="mt-2.5 font-normal text-[#5F646D] text-sm">
            A summary of all changes made
          </p>
        </div>

        <div id="changeLogTable" className="mt-5">
          <Table<AttractionChangeLog>
            // <table id>
            tableId="changelog"
            // <table className>
            tableClassNames="border-none w-full border-separate border-spacing-0 relative min-h-cs-50 xl:min-h-cs-150"
            // <thead className>
            tableHeadTrThClassNames="relative first-of-type:border-l text-sm font-bold text-black text-left px-4 py-5 hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
            // <tbody className>
            tableTbodyClassNames={`relative ${
              page.length === 0 ? "h-[60px]" : ""
            }`}
            // Mobile <tbody className>
            tableTbodyMobileClassNames="block"
            // <tbody><tr className>
            tableTbodyTrClassNames="h-1"
            // Empty <tbody><tr className>
            tableTbodyTrEmptyMobileClassNames="border-none"
            // Mobile <tbody><tr className>
            tableTbodyTrMobileClassNames="flex bg-white flex-col relative shadow-cs-admin-table-td overflow-hidden border border-[#CFDBD5] font-sm font-normal text-cs-gray rounded-xl p-3"
            // <tbody><tr><td className>
            tableTbodyTrTdClassNames="bg-white px-4 py-6 text-black font-normal text-sm break-all first-of-type:border-l first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl last-of-type:border-l-none last-of-type:border-r"
            // Empty <tbody><tr><td className>
            tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-0 last-of-type:border-r-0"
            // Mobile <tbody><tr><td className>
            tableTbodyTrEmptyTdMobileClassNames="h-2"
            // Hide Columns On Mobile
            hideColumnOnMobile={["Last Logged In", "Status", "Attractions"]}
            // react-table props
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            pages={tableData?.total}
            prepareRow={prepareRow}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            setPageSize={setPageSize}
            isFetching={isFetching}
          />
        </div>
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default AttracionChangeLog;

function removeHtmlTags(text: string) {
  return text.replace(/<[^>]+>/g, "");
}

function shortenDiffChanges(
  diff: { added: boolean; removed: boolean; value: string }[]
) {
  return diff.map((d, i) => {
    if (d.added || d.removed) return d;

    const words = d.value.split(" ");

    if (words.length <= 5) return d;

    let results: string[] = [];

    // Check if previous sibling is addition or removal
    if (diff[i - 1]?.added || diff[i - 1]?.removed) {
      results = [...words.slice(0, 5), "..."];
    }

    // Check if next sibling is addition or removal
    if (diff[i + 1]?.added || diff[i + 1]?.removed) {
      results = [...results, "...", ...words.slice(words.length - 5)];
    }

    return {
      ...d,
      value: results.join(" "),
    };
  });
}

function mapFaqQuestionsToHumanReadable(
  answer: string,
  prop: string,
  childProp: string
): string | ReactNode {
  if (answer === "true") {
    return "Yes";
  } else if (answer === "false") {
    return "No";
  } else if (isFaqAnswerAJSON(answer) && JSON.parse(answer)) {
    const parsed = JSON.parse(answer);
    if ((faqExtraNesting as any)?.[prop]?.[childProp]) {
      return (
        <ul>
          {Object.entries(parsed).map((a) => (
            <li>
              {(faqExtraNesting as any)?.[prop]?.[childProp]?.[a[0]] || a[0]}: {JSON.stringify(a[1])}
            </li>
          ))}
        </ul>
      );
    } else if (Object.keys(parsed).includes("value")) {
      return (
        <ul className="pl-4">
          <li>Value: {parsed?.value === "true" ? "Yes" : "No"}</li>
          {(parsed.note || parsed.extraNote) && (
            <li>Note: {parsed?.note || parsed?.extraNote || "-"}</li>
          )}
        </ul>
      );
    }
  }

  return answer;
}

function isFaqAnswerAJSON(answer: string) {
  try {
    const isJSON = JSON.parse(answer);
    return typeof isJSON === "object";
  } catch (e) {
    return false;
  }
}
