import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { z } from "zod";
import { useCallback, useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SubmitHandler,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form";
import TextInput from "../../../components/form/TextInput";
import Select from "react-select";
import SingleSelectOption from "../../../lib/react-select/SingleSelectOption";
import RichTextEditor from "../../../components/form/RichTextEditor";
import { apiSlice } from "../../../store/apiSlice";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { globalActions } from "../../../store/globalSlice";
import { UnsavedModal } from "../../../components/global/amp/UnsavedModal";
import { useWindowResize } from "../../../hooks/useWindowResize";
import { hasUrl } from "../../../helpers/hasUrl";
import { ErrorMessage } from "../../../components/global/ErrorMessage";
import { SingleAttractionTemplate } from "../../../components/global/SingleAttractionTemplate";
import { Button } from "../../../components/global/Button";
import { unlockNewAttractionTab } from "../../../helpers/newAttractionsTabPermission";
import { RevertToSavedModal } from "../../../components/global/RevertToSavedModal";
import Stepper from "../../../components/amp/Stepper";
import { filterPayload } from "../../../helpers/filterPayload";
import { SaveButtonsEditAttraction } from "../../../components/amp/SaveButtonsEditAttraction";
import { StepperHeadingEditAttraction } from "../../../components/amp/StepperHeadingEditAttraction";
import { PointingArrowHintSm } from "../../../components/amp/PointingArrowHintSm";
import {
  clearNewAttractionStepLeftOver,
  getNewAttractionWhichStepLeftOver,
  setNewAttractionLeftOverTab,
} from "../../../helpers/newAttractionsFinishLater";
import {
  Label,
  YesNoRadio,
  booleanValidation,
  getBooleanOrNull,
} from "./AttractionFAQs";
import { LightningIcon } from "../../../components/amp/LightBulbTooltip";
import {
  getVoScoreTooltip,
  useVoScoreNeedImprovementsFields,
} from "../../../hooks/useVoScoreNeedImprovementsFields";

interface IAttractionDetails {
  showPublishingButtons: boolean;
}

const categories: { value: string; label: string }[] = [
  { value: "3_and_under", label: "Babies and Toddlers" },
  { value: "younger_children", label: "Child" },
  { value: "adult", label: "Adult" },
];

const ages: { value: string; label: string }[] = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18+", label: "18+" },
];

const defaultPrice = {
  category: "3_and_under",
  age: {
    from: "0",
    to: "3",
  },
  price: null,
  isFree: false,
};
const defaultEmptyPriceArray = [
  {
    category: "younger_children",
    age: {
      from: "4",
      to: "17",
    },
    price: null,
    isFree: false,
  },
  {
    category: "adult",
    age: {
      from: "17",
      to: "18+",
    },
    price: null,
    isFree: false,
  },
];

const AttractionPrices: React.FC<IAttractionDetails> = () => {
  const windowSize = useWindowResize();
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const [getShowRevertModal, setShowRevertModal] = useState<boolean>(false);
  const { attractionId } = useParams();
  const {
    data: getAttraction,
    isSuccess: isGetSuccess,
    isFetching,
    refetch,
    error: attractionError,
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");
  const { result } = getAttraction ?? {};

  const [updateAttraction, { isLoading, isError, isSuccess: isUpdateSuccess }] =
    apiSlice.useUpdateAttractionDraftMutation();

  const schema = z.object({
    tickets: z.object({
      sell_tickets: z
        .boolean({
          invalid_type_error: "Please select Yes or No",
        })
        .nullable()
        .superRefine((val, ctx) => {
          if (typeof val !== "boolean" && user?.role !== "ADMIN") {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Please select Yes or No",
            });
          }
        }),
      discount: z
        .boolean({
          invalid_type_error: "Please select Yes or No",
        })
        .nullable()
        .superRefine((val, ctx) => {
          if (typeof val !== "boolean" && user?.role !== "ADMIN") {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Please select Yes or No",
            });
          }
        }),
    }),
    prices: z
      .object({
        category: z.enum(categories.map((c) => c.value) as [string]),
        age: z.object({
          from: z.enum(["no_min", ...ages.map((a) => a.value)] as [string]),
          to: z.enum(["no_max", ...ages.map((a) => a.value)] as [string]),
        }),
        price: z.coerce
          .number({ invalid_type_error: "Please enter a number" })
          .max(9000, { message: "Please input a price less than 9000 pounds." })
          .refine((v) => {
            return v !== null;
          })
          .nullable()
          .transform((v) => {
            return v != null && Number.isInteger(v)
              ? parseInt(v.toString().split(".")[0])
              : v;
          }),
        isFree: z.boolean(),
      })
      .superRefine((val, ctx) => {
        if ((val.price ?? 0) <= 0 && !val.isFree) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Enter a price or choose free.",
            path: ["price"],
          });
        }

        if ((val.price === null || val.price < 0) && !val.isFree) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "required.",
            path: ["price"],
          });
        }
      })
      .array()
      .min(1),
    description: z.string().superRefine((value, ctx) => {
      if (hasUrl(value ?? "")) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `You are not allowed include a url.`,
        });
      }
    }),
  });

  type AttractionPricesForm = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors, isDirty, dirtyFields },
    control,
    watch,
    getValues,
    setValue,
    reset,
    trigger: triggerValidation,
  } = useForm<AttractionPricesForm>({
    resolver: zodResolver(schema),
    shouldUseNativeValidation: true,
    defaultValues: {
      prices: [...defaultEmptyPriceArray],
      description: "",
      tickets: {
        discount: null,
        sell_tickets: null,
      },
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "prices",
    rules: {
      minLength: 1,
    },
  });
  const getCategory = (value: string) => {
    return categories.find((c) => c.value === value);
  };
  const getAge = (
    value: string,
    additional: { label: string; value: string }[] = []
  ) => {
    return [...additional, ...ages].find((c) => c.value === value);
  };

  const [isPublishing, setIsPublishing] = useState(false);
  const [stepsFields] = useState<(keyof AttractionPricesForm)[][]>([
    ["prices", "description", "description", "tickets"],
  ]);
  const [stepsPostFields] = useState<string[][]>([
    ["pricing_json", "pricing_html", "faqs_json"],
  ]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = stepsFields[currentStepIndex] ?? [];
  const currentPostStep = stepsPostFields[currentStepIndex] ?? [];
  const { user } = useAppSelector((state) => state.global);
  const isStepperActive =
    user?.role !== "ADMIN" && !result?.finished_onboarding;

  const getPayload = () => {
    return {
      pricing_html: getValues("description"),
      pricing_json: JSON.stringify(getValues("prices")),
      faqs_json: {
        ...result?.faqs_json,
        tickets: {
          ...result?.faqs_json.tickets,
          sell_tickets: getValues("tickets.sell_tickets"),
          discount: getValues("tickets.discount"),
        },
      },
    };
  };

  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<AttractionPricesForm> = async (e) => {
      setIsPublishing(publish);
      const rawPayload = getPayload();
      const payload = filterPayload(
        rawPayload,
        currentPostStep as (keyof typeof rawPayload)[],
        isStepperActive
      );
      const results = await updateAttraction({
        id: attractionId,
        publish,
        payload,
      });
      if (!isSilent) {
        restartAnimation();
      }

      return "data" in results;
    };

    return fn;
  };

  const bypassQueue =
    user?.role === "ADMIN" ||
    ["ENHANCED", "PREMIUM"].includes(result?.listing_package ?? "");

  useEffect(() => {
    if (isUpdateSuccess) {
      reset({}, { keepValues: true });

      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: isPublishing
            ? bypassQueue
              ? "Published"
              : "Submitted for approval"
            : "Your changes have been saved",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: isPublishing
            ? "Cannot publish attraction"
            : "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const addPrice = () => {
    append(defaultPrice);
  };

  const changedCategory = (value: string, index: number) => {
    if (!fields[index]) return;

    switch (value) {
      case "3_and_under":
        setValue(`prices.${index}.age.from`, "0");
        setValue(`prices.${index}.age.to`, "3");
        break;
      case "younger_children":
        setValue(`prices.${index}.age.from`, "4");
        setValue(`prices.${index}.age.to`, "17");
        break;
      case "adult":
        setValue(`prices.${index}.age.from`, "17");
        setValue(`prices.${index}.age.to`, "18+");
        break;
    }
  };

  useEffect(() => {
    if (result && isGetSuccess && !isFetching) {
      const pricing_json = (
        typeof result?.pricing_json === "string"
          ? JSON.parse(result?.pricing_json as string)
          : result?.pricing_json
      ) as AttractionPricesForm["prices"];

      const sellingTickets = getBooleanOrNull(
        result?.faqs_json?.tickets?.sell_tickets
      );
      const discount = getBooleanOrNull(result?.faqs_json?.tickets?.discount);
      const newPrices =
        pricing_json && pricing_json.length > 0
          ? pricing_json.map((p) => ({
              ...p,
              isFree: p.isFree ? p.isFree : p.price === 0,
            }))
          : [...defaultEmptyPriceArray];

      if (newPrices.length > 0) {
        reset(
          {
            prices: [],
          },
          { keepDirty: false }
        );
        setTimeout(() => {
          setValue("prices", [...newPrices]);
        }, 10);
      }
      reset(
        {
          description: result?.pricing_html ?? "",

          tickets: {
            sell_tickets: sellingTickets,
            discount: discount,
          },
        },
        { keepDirty: false }
      );
      setShowRevertModal(false);
    }
  }, [result, isFetching]);

  useEffect(() => {
    document.title = "Attraction Prices | Day Out With The Kids";
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionError]);
  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 404
    ) {
      navigate("/not-found", { replace: true });
    }
  }, [attractionError]);

  const stepperStep = useState(0);
  useEffect(() => {
    const steps = getNewAttractionWhichStepLeftOver();
    if (steps.step && steps.allSteps) {
      if (steps.allSteps === stepsFields.length) {
        stepperStep[1](steps.step);
        setCurrentStepIndex(steps.step);
      }
      clearNewAttractionStepLeftOver();
    }
  }, []);

  useEffect(() => {
    dispatch(
      globalActions.setSaveCurrentTabStep({
        attractionId: attractionId ?? "",
        key: "ATTRACTION_PRICES",
        allSteps: stepsFields.length,
        step: currentStepIndex,
      })
    );
  }, [attractionId, currentStepIndex, stepsFields.length, dispatch]);

  const { data: getMetrics } = apiSlice.useGetVenueOptScoreMetricsQuery();
  const voScoreImprovements = useVoScoreNeedImprovementsFields({
    allMetrics: getMetrics?.result || [],
    completedMetrics:
      getAttraction?.result?.published_version?.optimisation_scores || [],
    results: result,
  })["ATTRACTION_PRICES"];

  const YesNoQuestions = useCallback(() => {
    return (
      <>
        <div>
          <Label>
            Would you be interested in selling tickets via your DOWTK page?{" "}
            {!!voScoreImprovements.ticketBookable && (
              <LightningIcon
                tooltip={getVoScoreTooltip(voScoreImprovements.ticketBookable)}
                className="ml-1"
              />
            )}
          </Label>

          <div className="text-sm text-[#5F646D] font-normal mt-5">
            Converting our audience into ticket sales has never been easier,
            with our new ‘Ticket Manager’ platform.
          </div>

          <div>
            <Controller
              control={control}
              name={`tickets.sell_tickets`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="tickets.sell_tickets"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  afterYes={
                    <div className="text-sm text-[#5F646D] font-normal my-2.5 pl-2.5">
                      A member of the team will be in touch!
                    </div>
                  }
                />
              )}
            />
          </div>
        </div>

        <div>
          <Label>
            Would you be interested in featuring a discount within our closed
            membership subscription? {!!voScoreImprovements.vipDiscount && (
              <LightningIcon
                tooltip={getVoScoreTooltip(voScoreImprovements.vipDiscount)}
                className="ml-1"
              />
            )}
          </Label>

          <div className="text-sm text-[#5F646D] font-normal mt-5">
            Reach new audiences with our VIP Pass, the UK’s number one days out
            saving membership.
          </div>

          <div>
            <Controller
              control={control}
              name={`tickets.discount`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="tickets.discount"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  afterYes={
                    <div className="text-sm text-[#5F646D] font-normal my-2.5 pl-2.5">
                      A member of the team will be in touch!
                    </div>
                  }
                />
              )}
            />
          </div>
        </div>
      </>
    );
  }, [control, voScoreImprovements]);

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Edit Attraction"
        mobilePublishButtons={true}
        showPublishingButtons
        triggerValidation={handleSubmit}
        saveFn={onSubmit}
        getPayload={getPayload}
        isDirty={isDirty}
      />

      <SingleAttractionTemplate
        result={result}
        attractionId={attractionId}
        isAttraction={true}
        doneWalkthrough={result ? result?.done_walkthrough ?? false : undefined}
        name={isStepperActive ? result?.name ?? "" : ""}
        unstyled={!isStepperActive}
      >
        <form onSubmit={handleSubmit(onSubmit(false))}>
          <Stepper
            controlledStepping={stepperStep}
            isActive={isStepperActive}
            isLoading={isLoading}
            saveFn={onSubmit}
            onStepChange={setCurrentStepIndex}
            triggerValidation={async () => {
              return await triggerValidation(currentStep);
            }}
            onNextPage={async () => {
              unlockNewAttractionTab(attractionId ?? "", "ATTRACTION_FAQS");
              await new Promise((res) => setTimeout(res, 500));
              navigate(`/attractions/${attractionId}/faqs`);
            }}
            title={
              <StepperHeadingEditAttraction
                name={result?.name || ""}
                title={
                  <>
                    💰 Prices{" "}
                    {!isStepperActive && !!voScoreImprovements.pricing_json && (
                      <LightningIcon
                        tooltip={getVoScoreTooltip(
                          voScoreImprovements.pricing_json
                        )}
                      />
                    )}
                  </>
                }
                isActive={isStepperActive}
              />
            }
            nonStepperSubmit={
              <SaveButtonsEditAttraction isLoading={isLoading} />
            }
            onBeforeFinishLater={() => {
              setNewAttractionLeftOverTab(
                attractionId ?? "",
                "ATTRACTION_PRICES",
                currentStepIndex,
                stepsFields.length
              );
            }}
            onGoPrevPage={() => {
              navigate(`/attractions/${attractionId}/opening-times`);
            }}
          >
            <Stepper.Step componentType="Step">
              <fieldset>
                <p className="text-sm font-normal text-black">
                  Please choose an age range, and select the correct prices. Add
                  as many rows as you need!
                </p>
                <div className="inline-flex flex-col flex-wrap w-full mb-4 md:w-3/5 xl:w-[56%] max-w-[550px]">
                  {fields.map((field, index) => (
                    <div
                      key={field.id}
                      className={`flex flex-col md:flex-row gap-2 mb-2`}
                    >
                      <div>
                        {(index === 0 || windowSize?.width! <= 768) && (
                          <label className="flex mt-4 mb-3 text-sm font-normal text-[#5F646D]">
                            Category
                          </label>
                        )}

                        <Controller
                          control={control}
                          name={`prices.${index}.category`}
                          render={({
                            field: { ref, value, onChange },
                            fieldState: { error },
                          }) => (
                            <Select
                              defaultValue={
                                watch("prices")?.[index]?.category
                                  ? getCategory(watch("prices")[index].category)
                                  : categories[0]
                              }
                              components={{
                                Option: SingleSelectOption,
                                DropdownIndicator: () => (
                                  <div className="px-2.5">
                                    <svg
                                      width="10"
                                      height="6"
                                      viewBox="0 0 10 6"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>
                                ),
                                IndicatorSeparator: () => null,
                              }}
                              options={categories}
                              isSearchable={true}
                              styles={{
                                menu: (styles) => ({
                                  ...styles,
                                  margin: 0,
                                  border: "none",
                                }),
                                menuList: (base, isSelected) => ({
                                  ...base,
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  background: "white",

                                  "::-webkit-scrollbar": {
                                    width: 14,
                                  },
                                  "::-webkit-scrollbar-thumb": {
                                    background: "#c2c2c2",
                                    borderRadius: 10,
                                    border: "4px solid white",
                                  },
                                }),
                                option: (
                                  base,
                                  { data, isDisabled, isFocused, isSelected }
                                ) => {
                                  return {
                                    ...base,
                                    background: "white",
                                    border: "1px solid #c2c2c2",
                                    color: isSelected ? "white" : "#555",
                                    "&:hover": {
                                      backgroundColor: "#F1F1F1",
                                      color:
                                        isSelected || isFocused
                                          ? "#000"
                                          : "#555",
                                      border: isFocused
                                        ? "1px solid white"
                                        : "",
                                    },
                                    "&:active": {
                                      backgroundColor: "#6836D1",
                                      color: "#000",
                                    },
                                  };
                                },
                                singleValue: (base) => ({
                                  ...base,
                                  color: "#555",
                                  fontSize: 14,
                                }),
                                control: (styles, state) => ({
                                  ...styles,
                                  cursor: "pointer",
                                  outline: "none",
                                  border: state.isFocused
                                    ? "1px solid #CED3CF"
                                    : `1px solid ${error ? "red" : "#CED3CF"}`,
                                  "&:hover": {
                                    border: state.isFocused
                                      ? "1px solid #CED3CF"
                                      : "1px solid #CED3CF",
                                  },
                                  boxShadow: "none",
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                  margin: 0,
                                  minHeight: 40,
                                  height: 40,
                                  width: 188,
                                  borderRadius: 8,
                                }),
                                valueContainer: (styles) => ({
                                  ...styles,
                                  paddingRight: 20,
                                  paddingLeft: 20,
                                  fontSize: 14,
                                }),
                              }}
                              onChange={(v) => {
                                const options = categories.map(
                                  (c) => c.value
                                ) as [string];
                                const value = options.includes(
                                  v?.value! as string
                                )
                                  ? v?.value!
                                  : options[0];

                                onChange(value);
                                changedCategory(value, index);
                              }}
                            />
                          )}
                        />
                      </div>

                      <div className="">
                        {(index === 0 || windowSize?.width! <= 768) && (
                          <label className="flex mt-4 mb-3 text-sm font-normal text-[#5F646D]">
                            Age range
                          </label>
                        )}

                        <div className="flex gap-2.5">
                          <div className="min-w-[85px] relative">
                            <Controller
                              control={control}
                              name={`prices.${index}.age.from`}
                              render={({
                                field: { ref, value, onChange },
                                fieldState: { error },
                              }) => (
                                <Select
                                  defaultValue={
                                    watch("prices")?.[index]?.age?.from
                                      ? getAge(
                                          watch("prices")[index].age.from,
                                          [{ label: "No min", value: "no_min" }]
                                        )
                                      : ages[0]
                                  }
                                  components={{
                                    Option: SingleSelectOption,
                                    DropdownIndicator: () => (
                                      <div className="px-2.5">
                                        <svg
                                          width="10"
                                          height="6"
                                          viewBox="0 0 10 6"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </div>
                                    ),
                                    IndicatorSeparator: () => null,
                                  }}
                                  value={getAge(value, [
                                    { label: "No min", value: "no_min" },
                                  ])}
                                  options={[
                                    { label: "No min", value: "no_min" },
                                    ...ages,
                                  ]}
                                  isSearchable={true}
                                  styles={{
                                    menu: (styles) => ({
                                      ...styles,
                                      margin: 0,
                                      border: "none",
                                      zIndex: 15,
                                    }),
                                    menuList: (base, isSelected) => ({
                                      ...base,
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                      background: "white",

                                      "::-webkit-scrollbar": {
                                        width: 14,
                                      },
                                      "::-webkit-scrollbar-thumb": {
                                        background: "#c2c2c2",
                                        borderRadius: 10,
                                        border: "4px solid white",
                                      },
                                    }),
                                    option: (
                                      base,
                                      {
                                        data,
                                        isDisabled,
                                        isFocused,
                                        isSelected,
                                      }
                                    ) => {
                                      return {
                                        ...base,
                                        background: "white",
                                        border: "1px solid #c2c2c2",
                                        color: isSelected ? "white" : "#555",
                                        "&:hover": {
                                          backgroundColor: "#F1F1F1",
                                          color:
                                            isSelected || isFocused
                                              ? "#000"
                                              : "#555",
                                          border: isFocused
                                            ? "1px solid white"
                                            : "",
                                        },
                                        "&:active": {
                                          backgroundColor: "#6836D1",
                                          color: "#000",
                                        },
                                      };
                                    },
                                    singleValue: (base) => ({
                                      ...base,
                                      color: "#555",
                                      fontSize: 14,
                                    }),
                                    control: (styles, state) => ({
                                      ...styles,
                                      cursor: "pointer",
                                      outline: "none",
                                      border: state.isFocused
                                        ? "1px solid #CED3CF"
                                        : `1px solid ${
                                            error ? "red" : "#CED3CF"
                                          }`,
                                      "&:hover": {
                                        border: state.isFocused
                                          ? "1px solid #CED3CF"
                                          : "1px solid #CED3CF",
                                      },
                                      boxShadow: "none",
                                      paddingTop: 3,
                                      paddingBottom: 3,
                                      margin: 0,
                                      minHeight: 40,
                                      height: 40,
                                      width: 105,
                                      borderRadius: 8,
                                    }),
                                  }}
                                  onChange={(v) => onChange(v?.value)}
                                />
                              )}
                            />
                          </div>
                          <div className="min-w-[85px] relative">
                            <Controller
                              control={control}
                              name={`prices.${index}.age.to`}
                              render={({
                                field: { ref, value, onChange },
                                fieldState: { error },
                              }) => (
                                <Select
                                  defaultValue={
                                    watch("prices")?.[index]?.age?.to
                                      ? getAge(watch("prices")[index].age.to, [
                                          { label: "No max", value: "no_max" },
                                        ])
                                      : ages[0]
                                  }
                                  components={{
                                    Option: SingleSelectOption,
                                    DropdownIndicator: () => (
                                      <div className="px-2.5">
                                        <svg
                                          width="10"
                                          height="6"
                                          viewBox="0 0 10 6"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                                            fill="black"
                                          />
                                        </svg>
                                      </div>
                                    ),
                                    IndicatorSeparator: () => null,
                                  }}
                                  value={getAge(value, [
                                    { label: "No max", value: "no_max" },
                                  ])}
                                  options={[
                                    { label: "No max", value: "no_max" },
                                    ...ages,
                                  ]}
                                  isSearchable={true}
                                  styles={{
                                    menu: (styles) => ({
                                      ...styles,
                                      margin: 0,
                                      border: "none",
                                      zIndex: 15,
                                    }),
                                    menuList: (base, isSelected) => ({
                                      ...base,
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                      background: "white",

                                      "::-webkit-scrollbar": {
                                        width: 14,
                                      },
                                      "::-webkit-scrollbar-thumb": {
                                        background: "#c2c2c2",
                                        borderRadius: 10,
                                        border: "4px solid white",
                                      },
                                    }),
                                    option: (
                                      base,
                                      {
                                        data,
                                        isDisabled,
                                        isFocused,
                                        isSelected,
                                      }
                                    ) => {
                                      return {
                                        ...base,
                                        background: "white",
                                        border: "1px solid #c2c2c2",
                                        color: isSelected ? "#000" : "#555",
                                        "&:hover": {
                                          backgroundColor: "#F1F1F1",
                                          color:
                                            isSelected || isFocused
                                              ? "000"
                                              : "#555",
                                          border: isFocused
                                            ? "1px solid white"
                                            : "",
                                        },
                                        "&:active": {
                                          backgroundColor: "#6836D1",
                                          color: "#000",
                                        },
                                      };
                                    },
                                    singleValue: (base) => ({
                                      ...base,
                                      color: "#555",
                                      fontSize: 14,
                                    }),
                                    control: (styles, state) => ({
                                      ...styles,
                                      cursor: "pointer",
                                      outline: "none",
                                      border: state.isFocused
                                        ? "1px solid #CED3CF"
                                        : `1px solid ${
                                            error ? "red" : "#CED3CF"
                                          }`,
                                      "&:hover": {
                                        border: state.isFocused
                                          ? "1px solid #CED3CF"
                                          : "1px solid #CED3CF",
                                      },
                                      boxShadow: "none",
                                      paddingTop: 3,
                                      paddingBottom: 3,
                                      margin: 0,
                                      minHeight: 40,
                                      height: 40,
                                      width: 105,
                                      borderRadius: 8,
                                    }),
                                  }}
                                  onChange={(v) => onChange(v?.value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-[118px] relative">
                        <label
                          className={`text-black font-normal text-md absolute left-4 z-[5] ${
                            index === 0 || windowSize?.width! <= 768
                              ? "top-[57px]"
                              : "top-[9px]"
                          }`}
                        >
                          £
                        </label>
                        <TextInput
                          className={`w-full group ${
                            (index === 0 || windowSize?.width! <= 768) && "mt-4"
                          }`}
                          labelClassName="group-focus-within:text-cs-pink !text-[#5F646D] text-sm font-normal"
                          inputClassName="border-cs-1 text-xs border-cs-bright-lt-gray text-black rounded-lg py-3 pr-4 pl-8 flex flex-col w-full focus:outline-cs-pink h-10"
                          errorClassName="text-sm [&>.w-5]:hidden"
                          control={control}
                          name={`prices.${index}.price`}
                          label={
                            index === 0 || windowSize?.width! <= 768
                              ? "Price"
                              : ""
                          }
                          required
                          inputProps={{
                            ...register(`prices.${index}.price`, {
                              setValueAs: (v) =>
                                v === null || Number.isNaN(parseFloat(v))
                                  ? null
                                  : Number.isInteger(v)
                                  ? v
                                  : parseFloat(v).toFixed(2),
                              max: 9000,
                              async onBlur(e) {
                                new Promise((res) => setTimeout(res, 10));
                                if (e.target.value == 0) {
                                  setValue(`prices.${index}.isFree`, true);
                                }
                              },
                            }),
                            placeholder: "Enter price",
                            type: "string",
                          }}
                          readonly={
                            getValues(`prices.${index}.isFree`) === true
                          }
                        />
                      </div>

                      <label
                        className={`flex items-center text-sm font-normal checkbox-item primary-checkmark text-cs-gray w-[70px] whitespace-nowrap ${
                          index === 0
                            ? "md:mt-[60px] mt-2.5 md:mb-0 mb-2.5"
                            : "mt-2.5 md:mb-0 mb-2.5"
                        }`}
                      >
                        <span className="text-xs font-normal text-[#212121]">
                          Free
                        </span>
                        <Controller
                          control={control}
                          name={`prices.${index}.isFree`}
                          render={({
                            field: { ref, value, onChange },
                            fieldState: { error },
                          }) => (
                            <input
                              ref={ref}
                              className="!w-20"
                              type="checkbox"
                              checked={value}
                              onChange={(value) => {
                                if (value.target.checked) {
                                  setValue(`prices.${index}.price`, 0);
                                }
                                onChange(value);
                              }}
                            />
                          )}
                        />
                        <span className="checkmark radio-checkmark border-cs-bright-lt-gray"></span>
                      </label>

                      <button
                        className={`w-7 ${
                          index === 0 && fields.length === 1
                            ? "md:opacity-0 md:pointer-events-none"
                            : ""
                        }`}
                        type="button"
                        onClick={() => remove(index)}
                      >
                        <svg
                          className={`${index === 0 ? "mt-0 md:mt-10" : ""}`}
                          width="22"
                          height="23"
                          viewBox="0 0 22 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_14_12572)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7.90625 4.20508V2.14258H14.0938V4.20508H7.90625ZM5.84375 4.20508V1.45508C5.84375 1.09041 5.98862 0.740669 6.24648 0.482806C6.50434 0.224944 6.85408 0.0800781 7.21875 0.0800781L14.7812 0.0800781C15.1459 0.0800781 15.4957 0.224944 15.7535 0.482806C16.0114 0.740669 16.1562 1.09041 16.1562 1.45508V4.20508H19.5938C19.8673 4.20508 20.1296 4.31373 20.323 4.50712C20.5163 4.70052 20.625 4.96282 20.625 5.23633C20.625 5.50983 20.5163 5.77214 20.323 5.96553C20.1296 6.15893 19.8673 6.26758 19.5938 6.26758H19.0919L18.0702 19.5405C18.0172 20.2313 17.7054 20.8767 17.1971 21.3476C16.6887 21.8184 16.0214 22.08 15.3285 22.0801H6.6715C5.97861 22.08 5.31127 21.8184 4.80294 21.3476C4.29461 20.8767 3.98276 20.2313 3.92975 19.5405L2.90813 6.26758H2.40625C2.13275 6.26758 1.87044 6.15893 1.67705 5.96553C1.48365 5.77214 1.375 5.50983 1.375 5.23633C1.375 4.96282 1.48365 4.70052 1.67705 4.50712C1.87044 4.31373 2.13275 4.20508 2.40625 4.20508H5.84375ZM4.9775 6.26758H17.0225L16.0133 19.3823C16.0001 19.555 15.9223 19.7163 15.7953 19.8341C15.6684 19.9519 15.5017 20.0174 15.3285 20.0176H6.6715C6.49834 20.0174 6.33163 19.9519 6.20468 19.8341C6.07774 19.7163 5.99991 19.555 5.98675 19.3823L4.9775 6.26758Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_14_12572">
                              <rect
                                width="22"
                                height="22"
                                fill="white"
                                transform="translate(0 0.0800781)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  ))}

                  <Button
                    color="outlineGray"
                    className="!w-[170px] !px-8 mt-2.5"
                    scale="md"
                    type="button"
                    onClick={addPrice}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="mr-2"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z"
                      />
                    </svg>
                    Add a price
                  </Button>
                </div>
                {isStepperActive && (
                  <div className="h-[2px] bg-[#CFDBD5] w-full md:w-3/5 xl:w-[56%] max-w-[550px]"></div>
                )}
                <div
                  className={`w-full md:w-3/5 xl:w-[56%] max-w-[550px] mt-5`}
                >
                  <div className="relative">
                    {result?.pricing_html !== undefined && (
                      <RichTextEditor
                        placeholder="Please include information such as: Are there any seasonal variations in ticket prices? Are there any discounts or special offers available for certain groups, such as children, seniors, or students?"
                        labelClassName={"text-sm font-normal text-[#5F646D]"}
                        initialValue={result?.pricing_html}
                        onChange={(value: string) =>
                          setValue("description", value)
                        }
                        onDirty={() =>
                          setTimeout(() => {
                            setValue("description", getValues("description"), {
                              shouldDirty: true,
                            });
                          }, 100)
                        }
                        maxChars={200}
                        isWordCount={true}
                        label="Add other pricing information that has not previously been covered."
                        maxCharSuffix=" words"
                      />
                    )}
                    {isStepperActive && (
                      <PointingArrowHintSm
                        className="top-0 -right-[290px]"
                        boxClassName="w-[221px]"
                      >
                        <div>
                          <div>
                            What additional costs can visitors expect apart from
                            the entry cost?
                          </div>

                          <div className="mt-2.5">
                            We recommend stating:
                            <ul className="pl-4 list-disc">
                              <li>
                                The cost of extra activities, fast pass, food,
                                drink, etc.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </PointingArrowHintSm>
                    )}
                  </div>

                  {errors["description"]?.message && (
                    <ErrorMessage>
                      {errors["description"]?.message}
                    </ErrorMessage>
                  )}
                </div>

                {isStepperActive && (
                  <div className="h-[2px] bg-[#CFDBD5] w-full md:w-3/5 xl:w-[56%] max-w-[550px] mt-5"></div>
                )}

                {isStepperActive && (
                  <div className="mt-5">
                    <YesNoQuestions />
                  </div>
                )}
              </fieldset>
            </Stepper.Step>

            {!isStepperActive && (
              <Stepper.Step componentType="Step" grouping="b">
                <div>
                  <YesNoQuestions />
                </div>
              </Stepper.Step>
            )}
          </Stepper>

          {isSubmitted && !isValid && Object.keys(errors).length !== 0 && (
            <ErrorMessage>
              You have some errors! Scroll up to view & fix
            </ErrorMessage>
          )}
        </form>

        <RevertToSavedModal
          getShowRevertModal={getShowRevertModal}
          setShowRevertModal={setShowRevertModal}
          refetch={refetch}
        />

        <UnsavedModal
          isDirty={isDirty && JSON.stringify(dirtyFields) !== "{}"}
        />
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default AttractionPrices;
