import {
  UseTableInstanceProps,
  UsePaginationInstanceProps,
  UsePaginationState,
} from "react-table";
import { useWindowResize } from "../../hooks/useWindowResize";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import SingleSelectOption from "../../lib/react-select/SingleSelectOption";
import DropDownIndicator from "../../lib/react-select/DropDownIndicator";
import Pagination from "../../components/global/amp/Pagination";
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { original } from "@reduxjs/toolkit";
import { usePaginationPages } from "../../hooks/usePaginationPages";

interface CustomTableProps {
  tableId: string;
  tableClassNames?: string;
  tableHeadTrThClassNames?: string;
  tableTbodyClassNames?: string;
  tableTbodyMobileClassNames?: string;
  tableTbodyTrClassNames?: string;
  tableTbodyTrEmptyMobileClassNames?: string;
  tableTbodyTrMobileClassNames?: string;
  tableTbodyTrTdClassNames?: string;
  tableTbodyTrEmptyTdClassNames?: string;
  tableTbodyTrEmptyTdMobileClassNames?: string;
  hideColumnOnMobile: string[] | undefined;
  fullWidthMobileTd?: string[];
  spacedMobileTd?: string[];
  pages?: number;
  isFetching?: boolean;
  hideRowsPerPage?: boolean
  rowClick?: (val : any) => void,
  hidePagination?: boolean
}

interface TableNewProps<T extends object>
  extends Pick<
    UseTableInstanceProps<T>,
    "getTableProps" | "headerGroups" | "getTableBodyProps" | "prepareRow"
  > {}

interface PaginationNewProps<T extends object>
  extends Pick<
    UsePaginationInstanceProps<T>,
    "pageOptions" | "gotoPage" | "setPageSize" | "page"
  > {}

type FullTableProps<T extends object> = CustomTableProps &
  TableNewProps<T> &
  UsePaginationState<T> &
  PaginationNewProps<T>;

const Table = <T extends object>(props: FullTableProps<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const {
    tableId,
    tableClassNames,
    tableHeadTrThClassNames,
    tableTbodyClassNames,
    tableTbodyMobileClassNames,
    tableTbodyTrClassNames,
    tableTbodyTrEmptyMobileClassNames,
    tableTbodyTrMobileClassNames,
    tableTbodyTrTdClassNames,
    tableTbodyTrEmptyTdClassNames,
    tableTbodyTrEmptyTdMobileClassNames,
    hideColumnOnMobile,
    getTableProps,
    headerGroups,
    isFetching,
    getTableBodyProps,
    page,
    pages,
    prepareRow,
    pageIndex,
    pageSize,
    pageOptions,
    gotoPage,
    setPageSize,
    rowClick,
    hideRowsPerPage,
    hidePagination = false
  } = props;

  const { currentPage, oneOfTotalPages, goTo, goNext, goPrev } =
    usePaginationPages({
      pageIndex,
      gotoPage,
      length: pages!,
      pageSize,
    });

  const windowSize = useWindowResize();

  const allOptions: { value: string; label: string }[] | any = [
    {
      value: searchParams.get("pageSize") || "25",
      label: searchParams.get("pageSize") || "25",
    },
    { value: "5", label: "5" },
    { value: "25", label: "25" },
    { value: "50", label: "50" },
  ];

  const handleFilterSelectChange = (e: { value: string }) => {
    setPageSize(Number(e.value));
    gotoPage(0);
  };

  const [getExpandedRows, setExpandedRows] = useState<any>([]);

  useEffect(() => {
    const newData = page.map((page) => {
      return {
        expanded: false,
      };
    });

    setExpandedRows(newData);
  }, [page]);

  const onClickExpand = (index: number) => {
    const newItems = [...getExpandedRows];
    newItems.map((item, i) =>
      i === index ? (item.expanded = !item.expanded) : (item.expanded = false)
    );
    setExpandedRows(newItems);
  };

  return (
    <>
      <table id={tableId} {...getTableProps({ className: tableClassNames })}>
        <thead className="[&_th]:bg-white">
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={(headerGroup.id ?? 0) + i}>
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps([
                    { className: tableHeadTrThClassNames },
                    {
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                      },
                    },
                    column.getSortByToggleProps(),
                  ])}
                  key={column.id + i}
                  title={typeof column.Header !== "string" ? "" : column.Header}
                >
                  <>
                    <div className={`flex gap-2.5 ${(i !== 0 && tableId !== 'changelog' && tableId !== 'diariesTable') ? 'justify-center' : ''}`}>
                      {column.render("Header")}
                      {!column.canSort ? (
                        ""
                      ) : column.isSortedDesc ? (
                        <span className="w-[12px] flex flex-col">
                          <IconArrow className="opacity-50" />
                          <IconArrow className="rotate-180 mt-[2px]" />
                        </span>
                      ) : column.isSorted ? (
                        <span className="w-[12px] flex flex-col">
                          <IconArrow />
                          <IconArrow className="rotate-180 mt-[2px] opacity-50" />
                        </span>
                      ) : (
                        <span className="w-[12px] flex flex-col">
                          <IconArrow />
                          <IconArrow className="rotate-180 mt-[2px]" />
                        </span>
                      )}
                    </div>
                  </>
                </th>
              ))}
              {(!["attractionsTable", "changelog"].includes(tableId) ||
                (tableId === "attractionsTable" && page.length !== 0)) && (
                <th className={!['pendingApprovalTable'].includes(tableId) ? `!bg-transparent` : ''}></th>
              )}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps({
            className:
              windowSize?.width! <= 1280
                ? tableTbodyMobileClassNames
                : tableTbodyClassNames,
          })}
        >
          <>
            {(page.length === 0 || isFetching) && (
              <tr className="absolute left-2/4 top-2/4 -translate-y-2/4 -translate-x-2/4 text-cs-gray font-bold">
                <td>
                  <div className="text-black font-roboto-flex font-semibold">
                    {isFetching ? <div><LoadingIndicator /></div> : "No Records Found"}
                  </div>
                </td>
              </tr>
            )}
            {!isFetching && page.map((row, index) => {
              prepareRow(row);
              let emailMatchDomain =
                tableId == "pendingApprovalTable" && row?.values?.email?.props
                  ? new RegExp(
                      "\\b" +
                        row.values.email.props.children?.substring(
                          row.values.email.props.children.lastIndexOf("@") + 1
                        )
                    ).test(row.values.website.props.children)
                  : true;
              return (
                <React.Fragment key={row.id + "frag" + index}>
                  {index === 0 && (
                    <tr
                      className={`${
                        windowSize?.width! <= 1280
                          ? tableTbodyTrEmptyMobileClassNames
                          : tableTbodyTrClassNames
                      }`}
                    >
                      {row.cells.map((cell, i) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              className:
                                windowSize?.width! <= 1280
                                  ? tableTbodyTrEmptyTdMobileClassNames
                                  : tableTbodyTrEmptyTdClassNames,
                            })}
                            key={"empty-td" + i}
                          ></td>
                        );
                      })}
                    </tr>
                  )}
                  <tr
                    {...row.getRowProps({
                      className:
                        windowSize?.width! <= 1280
                          ? tableTbodyTrMobileClassNames +
                            (!emailMatchDomain
                              ? " border-cs-red bg-cs-off-white"
                              : "")
                          : tableTbodyTrClassNames +
                            (!emailMatchDomain ? "hidden bg-cs-off-white" : ""),
                    })}
                    key={row.id + index}
                    onClick={() => tableId === "diariesTable" ? rowClick && rowClick(row?.original) : undefined}
                  >
                    {row.cells.map((cell, i) => {
                      return windowSize?.width! <= 1280 ? (
                        !hideColumnOnMobile?.includes(
                          String(cell.column.Header)
                        ) ? (
                          cell.column.Header == "Actions" ? (
                            <React.Fragment key={cell.value + "mobile" + i}>
                              <td
                                className={`${
                                  getExpandedRows[index] &&
                                  getExpandedRows[index].expanded
                                    ? "block my-3"
                                    : "hidden"
                                }`}
                              >
                                {cell.render("Cell")}
                              </td>
                              <td
                                onClick={() => onClickExpand(index)}
                                className="absolute text-sm cursor-pointer text-cs-gray top-3 right-4"
                              >
                                {getExpandedRows[index] &&
                                getExpandedRows[index].expanded
                                  ? "View Less"
                                  : "View More"}
                              </td>
                            </React.Fragment>
                          ) : cell.column.Header == "Primary Image" ? (
                            <td
                              className={`w-20 top-0 absolute left-0 ${
                                getExpandedRows[index] &&
                                getExpandedRows[index].expanded
                                  ? "h-cs-60"
                                  : "h-full"
                              }`}
                              key={cell.value + "mobile" + i}
                            >
                              {cell.render("Cell")}
                            </td>
                          ) : cell.column.Header == "Attraction Name" ? (
                            <td
                              className="text-xs"
                              key={cell.value + "mobile" + i}
                            >
                              {cell.render("Cell")}
                            </td>
                          ) : cell.column.Header == "Address" ? (
                            <td
                              className={`${
                                getExpandedRows[index] &&
                                getExpandedRows[index].expanded
                                  ? "block border-b-cs-1 py-2 mt-6 text-sm"
                                  : "hidden"
                              }`}
                              key={cell.value + "mobile" + i}
                            >
                              <span className="block w-full text-sm underline">
                                Address
                              </span>
                              {cell.render("Cell")}
                            </td>
                          ) : cell.column.Header == "Date of claim" ? (
                            <td
                              className={`${
                                getExpandedRows[index] &&
                                getExpandedRows[index].expanded
                                  ? "block border-b-cs-1 py-2 text-sm"
                                  : "hidden"
                              }`}
                              key={cell.value + "mobile" + i}
                            >
                              <span className="block w-full text-sm underline">
                                Date of claim
                              </span>
                              {cell.render("Cell")}
                            </td>
                          ) : cell.column.Header == "Claimed by" ? (
                            <td
                              className={`${
                                getExpandedRows[index] &&
                                getExpandedRows[index].expanded
                                  ? "block border-b-cs-1 py-2 text-sm"
                                  : "hidden"
                              }`}
                              key={cell.value + "mobile" + i}
                            >
                              <span className="block w-full text-sm underline">
                                Claimed by
                              </span>
                              {cell.render("Cell")}
                            </td>
                          ) : cell.column.Header == "Verified" ? (
                            <td
                              className={`${
                                getExpandedRows[index] &&
                                getExpandedRows[index].expanded
                                  ? "block border-b-cs-1 py-2 text-sm"
                                  : "hidden"
                              }`}
                              key={cell.value + "mobile" + i}
                            >
                              <span className="block w-full text-sm underline">
                                Verified
                              </span>
                              {cell.render("Cell")}
                            </td>
                          ) : cell.column.Header == "Web / Email match" ? (
                            <td
                              className={`${
                                getExpandedRows[index] &&
                                getExpandedRows[index].expanded
                                  ? "block border-b-cs-1 py-2 relative"
                                  : "hidden"
                              }`}
                              key={cell.value + "mobile" + i}
                            >
                              <span className="block w-full text-sm underline">
                                Web / Email match
                              </span>
                              {cell.render("Cell")}
                            </td>
                          ) : cell.column.Header == "Already Claimed" ? (
                            <td
                              className={`${
                                getExpandedRows[index] &&
                                getExpandedRows[index].expanded
                                  ? "block border-b-cs-1 py-2 text-sm"
                                  : "hidden"
                              }`}
                              key={cell.value + "mobile" + i}
                            >
                              <span className="block w-full mb-2 text-sm underline">
                                Already Claimed?
                              </span>
                              {cell.render("Cell")}
                            </td>
                          ) : (
                            <td
                              className={
                                cell.column.Header == "ID"
                                  ? `absolute right-3 top-2/4 -translate-y-2/4 ${
                                      windowSize?.width! <= 1280
                                        ? "left-0 top-[36px] h-full cursor-pointer"
                                        : ""
                                    }`
                                  : cell.column.Header == "Last Name"
                                  ? "float-left break-all text-cs-gray text-base font-bold pr-5"
                                  : cell.column.Header == "Email"
                                  ? `float-left clear-both break-all text-sm font-normal ${
                                      props.fullWidthMobileTd?.includes(
                                        String(cell.column.Header)
                                      ) && windowSize?.width! <= 1280
                                        ? "w-full max-w-[calc(100vw-40px)] whitespace-nowrap"
                                        : "max-w-cs-75-percent"
                                    } pr-5`
                                  : `relative float-left mr-2 ${
                                      props.fullWidthMobileTd?.includes(
                                        String(cell.column.Header)
                                      ) && windowSize?.width! <= 1280
                                        ? "w-full max-w-[calc(100vw-40px)] whitespace-nowrap"
                                        : ""
                                    } break-all text-cs-gray text-base ${
                                      (
                                        props.spacedMobileTd ?? [
                                          "Updated",
                                          "Operator",
                                          "Package",
                                          "Date added",
                                          "Added by",
                                        ]
                                      ).includes(
                                        (cell.column.Header as string) ?? ""
                                      )
                                        ? getExpandedRows[index] &&
                                          getExpandedRows[index].expanded
                                          ? "ml-0"
                                          : "ml-cs-100"
                                        : ""
                                    }`
                              }
                              key={cell.value + "mobile" + i}
                            >
                              {cell.render("Cell")}
                            </td>
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        <td
                          {...cell.getCellProps({
                            className:
                              tableTbodyTrTdClassNames +
                              (!emailMatchDomain
                                ? " first-of-type:border-l-cs-red first-of-type:border-y-cs-red border-y-cs-red last-of-type:border-r-cs-red"
                                : ""),
                          })}
                          key={cell.value + i}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  <tr
                    className={`${
                      windowSize?.width! <= 1280
                        ? tableTbodyTrEmptyMobileClassNames
                        : tableTbodyTrClassNames
                    }`}
                  >
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            className:
                              windowSize?.width! <= 1280
                                ? tableTbodyTrEmptyTdMobileClassNames
                                : tableTbodyTrEmptyTdClassNames,
                          })}
                          key={"empty-td" + i}
                        ></td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          </>
        </tbody>
      </table>
      {location.pathname !== "/dashboard/pending-approval" && (
        <div className="flex flex-col items-center justify-between py-4 my-5 xl:py-0 xl:flex-row xl:mt-4 md:flex-row gap-y-4">
          <div className="flex items-center mb-2 xl:mb-0">
            {!hideRowsPerPage && <><span className="mr-2 font-normal text-black text-md xl:text-sm">
              Rows per page
            </span>
            <Select
              id="tableRowsPerPage"
              className="mr-2"
              defaultValue={allOptions[0]}
              components={{
                Option: SingleSelectOption,
                DropdownIndicator: () => (
                  <div className="px-2.5">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                ),
                IndicatorSeparator: () => null,
              }}
              onChange={handleFilterSelectChange}
              options={allOptions}
              isSearchable={false}
              styles={{
                menu: (styles) => ({
                  ...styles,
                  margin: 0,
                  border: "none",
                }),
                menuList: (base, isSelected) => ({
                  ...base,
                  paddingTop: 0,
                  paddingBottom: 0,
                  background: "white",
                  "::-webkit-scrollbar": {
                    width: 14,
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: "#c2c2c2",
                    borderRadius: 10,
                    border: "4px solid white",
                  },
                }),
                option: (base, { data, isDisabled, isFocused, isSelected }) => {
                  return {
                    ...base,
                    background: "white",
                    border: "1px solid #c2c2c2",
                    color: isSelected ? "white" : "#555",
                    "&:hover": {
                      backgroundColor: "#6836D1",
                      color: isSelected || isFocused ? "white" : "#555",
                      border: isFocused ? "1px solid white" : "",
                    },
                    "&:active": { backgroundColor: "#6836D1", color: "white" },
                    "&:first-of-type": {
                      backgroundColor: "#F3F3F3",
                      color: "#555",
                      border: "1px solid #c2c2c2",
                    },
                  };
                },
                singleValue: (base) => ({
                  ...base,
                  color: "black",
                  fontSize: 14,
                }),
                control: (styles, state) => ({
                  ...styles,
                  cursor: "pointer",
                  outline: "none",
                  border: "none",
                  boxShadow: "none",
                  paddingTop: 3,
                  paddingBottom: 3,
                  minHeight: 20,
                }),
              }}
            /></>}
          </div>

          {!hidePagination && !isFetching && <div className="flex items-center justify-center text-black font-roboto-flex">
            <Pagination
              pageIndex={Number(pageIndex)}
              gotoPage={gotoPage}
              length={pages!}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </div>}

          <div className="pr-2 font-normal text-center min-w-cs-80 xl:px-2 xl:text-sm">
            <span className="mr-1 font-bold text-black xl:text-sm">
              {oneOfTotalPages.length === 0
                ? 0
                : (pageIndex + 1) * pageSize - pageSize + 1}{" "}
              -{" "}
              {oneOfTotalPages.length === 0
                ? 0
                : (pageIndex + 1) * pageSize > pages!
                ? pages
                : (pageIndex + 1) * pageSize}
            </span>{" "}
            of{" "}
            <span className="ml-1 font-bold text-black xl:text-sm">
              {pages}
            </span>
            <span className="ml-1">total</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;

export function IconArrow({ className = "" }: { className?: string }) {
  return (
    <div className={`h-[9px] overflow-hidden ${className}`}>
      <svg
        width="12"
        height="19"
        viewBox="0 0 12 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="fa-solid:sort">
          <path
            id="Vector"
            d="M1.88112 10.4983H9.89967C10.6207 10.4983 10.9812 11.3709 10.4724 11.8796L6.46315 15.8889C6.14645 16.2056 5.63434 16.2056 5.32101 15.8889L1.30837 11.8796C0.799625 11.3709 1.16012 10.4983 1.88112 10.4983ZM10.4724 6.96067L6.46315 2.95139C6.14645 2.63469 5.63434 2.63469 5.32101 2.95139L1.30837 6.96067C0.799625 7.46941 1.16012 8.34202 1.88112 8.34202H9.89967C10.6207 8.34202 10.9812 7.46941 10.4724 6.96067Z"
            fill="black"
          />
        </g>
      </svg>
    </div>
  );
}

const LoadingIndicator: React.FC = () => {
  return (
    <svg
      className="animate-spin -ml-1 mr-3 h-5 w-5 text-cs-pink"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};