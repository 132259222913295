import { useEffect, useState } from "react";
import { apiSlice } from "../../store/apiSlice";

export function CampaignInterest() {
  const { data, isFetching, isSuccess, refetch } =
    apiSlice.useGetUserCampaignInterestsQuery();
  const [fetchedData, setFetchedData] = useState<{
    christmas_campaign_interest: boolean | null;
    all_campaigns_interest: boolean | null;
  } | null>(null);

  const [settingChristmasInterest, setSettingChristmasInterest] =
    useState(false);
  const [christmasCampaignInterest] =
    apiSlice.useChristmasCampaignInterestMutation();

  const [settingAllInterest, setSettingAllInterest] = useState(false);
  const [allCampaignInterest] = apiSlice.useAllCampaignInterestMutation();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [hideSections, setHideSections] = useState(true);

  useEffect(() => {
    if (data && !isFetching && isSuccess) {
      if (
        isFirstLoad &&
        data.all_campaigns_interest === true &&
        data.christmas_campaign_interest === true
      ) {
        setHideSections(true);
      } else {
        setHideSections(false);
      }

      setFetchedData(data);
      setIsFirstLoad(false);
    }
  }, [data, isFetching, isSuccess]);

  return !hideSections ? (
    <div className="mb-5 flex flex-wrap lg:flex-nowrap gap-5 xl:mx-[70px]">
      {true ? (
        <div className="relative bg-white rounded-lg p-6 border border-[#E1E7E5] w-[454px] max-w-full lg:max-w-[50%]">
          <div className="text-base font-medium leading-[19px] text-[#121110]">
            Start Selling Tickets in Minutes With Ticket Manager!
          </div>

          <div className="mt-2 font-normal text-sm leading-5 text-[#2F2D2C] pr-10">
            Start converting page views into sales with our new Ticket Manager
            tool, part of the Connect Management Platform.
            <br />
            <br />
            Due to the overwhelming response, we have decided to create a
            priority list for attractions to get onboarded as quickly as
            possible.
          </div>

          <a
            href="https://form.typeform.com/to/t1iHLrZX"
            target="_blank"
            rel="noreferrer"
            className={`bg-cs-pink border border-cs-pink w-[145px] h-[30px] flex justify-center items-center rounded-lg text-white font-normal text-xs mt-[12px]`}
          >
            Join the Priority List
          </a>
        </div>
      ) : (
        <div className="relative bg-white rounded-lg p-6 border border-[#EEEEEE] w-[490px] max-w-full lg:max-w-[50%]">
          <div className="text-base font-medium leading-[19px] text-[#121110]">
            Thanks for showing interest!
          </div>

          <div className="mt-2 font-normal text-sm leading-5 text-[#2F2D2C] pr-10">
            A member of our team will contact you to discuss the possibilities
            on offer for your attraction.
          </div>
        </div>
      )}

      {!fetchedData?.all_campaigns_interest ? (
        <div className="relative bg-white rounded-lg p-6 border border-[#E1E7E5] w-[454px] max-w-full lg:max-w-[50%]">
          <div className="text-base font-medium leading-[19px] text-[#121110]">
            Boost Your Attraction's Visibility with Year-Round Exposure on Our
            Website in 2025
          </div>

          <div className="mt-2 font-normal text-sm leading-5 text-[#2F2D2C] pr-10">
            Plan ahead and ensure your attraction gets maximum exposure
            throughout the year! Upcoming campaigns include...
            <ul className="font-normal text-sm leading-5 text-[#2F2D2C] list-disc pl-5">
              <li>February Half Term Highlights</li>
              <li>Our Easter Bonanza</li>
              <li>Summer Thrills</li>
              <li>and much more!</li>
            </ul>
          </div>

          <button
            type="button"
            className={`bg-cs-pink border border-cs-pink w-[126px] h-[30px] flex justify-center items-center rounded-lg text-white font-normal text-xs mt-[12px] ${
              fetchedData?.all_campaigns_interest || settingAllInterest
                ? "pointer-events-none opacity-50"
                : ""
            }`}
            onClick={async (event) => {
              event.preventDefault();
              if (fetchedData?.all_campaigns_interest) return;
              setSettingAllInterest(true);

              const result = await allCampaignInterest();

              if ("data" in result) {
                refetch();
              }

              setSettingAllInterest(false);
            }}
          >
            Register Interest
          </button>
        </div>
      ) : (
        <div className="relative bg-white rounded-lg p-6 border border-[#EEEEEE] w-[490px] max-w-full lg:max-w-[50%]">
          <div className="text-base font-medium leading-[19px] text-[#121110]">
            Thanks for showing interest!
          </div>

          <div className="mt-2 font-normal text-sm leading-5 text-[#2F2D2C] pr-10">
            A member of our team will contact you to discuss the possibilities
            on offer for your attraction.
          </div>
        </div>
      )}
    </div>
  ) : null;
}
