import Header from "../../components/global/amp/Header";
import Footer from "../../components/global/amp/Footer";
import { z } from "zod";
import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import TextInput from "../../components/form/TextInput";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { apiSlice } from "../../store/apiSlice";
import {
  restartAnimation,
} from "../../components/global/amp/ToastNotification";
import { globalActions } from "../../store/globalSlice";
import { SingleAttractionTemplate } from "../../components/global/SingleAttractionTemplate";
import { PrimaryButton } from "../../components/global/PrimaryButton";
import { ErrorMessage } from "../../components/global/ErrorMessage";
import { OutlineButton } from "../../components/global/OutlineButton";

interface MyAccountForm {
  firstName: string;
  lastName: string;
  businessName: string;
  businessEmail: string;
  phoneNumber: string;
}

const MyAccount: React.FC = () => {
  const { user, toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const schema = z.object({
    firstName: z.string().min(1, "First name is required"),
    lastName: z.string().min(1, "Last name is required"),
    businessName: z.string().max(255, 'Too long').optional(),
    businessEmail: z
      .string()
      .email("Enter a valid email address")
      .optional()
      .or(z.literal("")),
    phoneNumber: z
      .string()
      .min(10, "Phone number is required")
      .refine(
        (val) =>
          new RegExp(
            /^(\+44\s?|\(0\d{4}\)\s?|\(0\d{3}\)\s?|\(0\d{2}\)\s?|\d{4}\s?|\d{3}\s?|\d{2}\s?)\d{3}\s?\d{4}(\s?\#\d{3,4})?$/
          ).test(val),
        "Invalid phone number. It should be at least 10 digits with no spaces"
      ),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isSubmitted, errors },
    control,
    setValue,
    getValues,
  } = useForm<MyAccountForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      businessName: "",
      businessEmail: "",
      phoneNumber: "",
    },
  });

  const [
    updateUser,
    { isLoading, isError, isSuccess, isUninitialized, reset, error },
  ] = apiSlice.useUpdateUserMutation();

  const [requestPasswordReset, requestPasswordResetQueryData] =
    apiSlice.usePasswordResetRequestMutation();
  const {
    isError: isErrorResetPassword,
    isSuccess: isSuccessResetPassword,
    isLoading: isLoadingResetPassword,
    isUninitialized: isUninitializedResetPassword,
  } = requestPasswordResetQueryData;

  const onSubmit: SubmitHandler<MyAccountForm> = async (e) => {
    await updateUser({
      id: user?.auth0_id,
      payload: {
        first_name: getValues("firstName"),
        last_name: getValues("lastName"),
        email: getValues("businessEmail"),
        phone: getValues("phoneNumber"),
      },
    });
    restartAnimation();
  };

  const onSubmitResetPassword: SubmitHandler<MyAccountForm> = async (e) => {
    if (user?.email) {
      await requestPasswordReset(user?.email.toString());
      restartAnimation();
    }
  };

  useEffect(() => {
    document.title = "My Account | Day Out With The Kids";
  }, []);

  useEffect(() => {
    isSuccess &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Your changes have been saved",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isSuccess]);

  useEffect(() => {
    isSuccessResetPassword &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Check your email to reset your password",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isSuccessResetPassword]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  useEffect(() => {
    isErrorResetPassword &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isErrorResetPassword]);

  useEffect(() => {
    user && setValue("firstName", user?.first_name ?? "");
    setValue("lastName", user?.last_name ?? "");
    setValue("businessName", user?.operator?.title ?? "");
    setValue("businessEmail", user?.email ?? "");
    setValue("phoneNumber", user?.phone ?? "");
  }, [user]);

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header showBack title="Account Details" />

      <SingleAttractionTemplate name={"User Details"}>
        <form className="pb-10" onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="flex flex-wrap w-full mb-4 md:w-3/4 xl:w-2/5">
            <div className="w-full grid grid-cols-2 gap-2">
              <TextInput
                className="w-full mt-1 group"
                labelClassName="group-focus-within:text-cs-pink font-bold"
                inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                control={control}
                name={"firstName"}
                label="First Name"
                required
                inputProps={{ ...register("firstName") }}
              />
              <TextInput
                className="w-full mt-1 mb-3 group"
                labelClassName="group-focus-within:text-cs-pink font-bold"
                inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                control={control}
                name={"lastName"}
                label="Last Name"
                required
                inputProps={{ ...register("lastName") }}
              />
            </div>

            <TextInput
              className="w-full mt-1 mb-3 group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"businessName"}
              label="Business Name"
              readonly={user?.role != "ADMIN" ? true : false}
              inputProps={{ ...register("businessName") }}
            />
            <TextInput
              className="w-full mt-1 group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"businessEmail"}
              label="Your business email address"
              readonly={user?.role != "ADMIN" ? true : false}
              inputProps={{ ...register("businessEmail") }}
            />
            <span className="mb-10 text-sm text-cs-gray md:pr-10">
              If you wish to change your business name or business email address
              then please{" "}
              <Link
                className="text-sm font-semibold underline hover:text-cs-pink"
                to={"#"}
              >
                contact support
              </Link>
            </span>

            <TextInput
              className="w-full mt-1 group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"phoneNumber"}
              label="Phone number"
              required
              inputProps={{ ...register("phoneNumber") }}
            />
          </fieldset>

          <div className="">
            <PrimaryButton scale="sm" type="submit" loading={isLoading}>
              Save
            </PrimaryButton>
          </div>
          {isSubmitted && !isValid && Object.keys(errors).length != 0 && (
            <ErrorMessage>
              Please fix the errors indicated above to continue
            </ErrorMessage>
          )}
        </form>

        <form
          className="pt-8 border-t-2 border-cs-off-white"
          onSubmit={handleSubmit(onSubmitResetPassword)}
        >
          <h3 className="text-[22px] font-extrabold text-black">Security</h3>
          <p className="mt-2 mb-4 text-sm font-normal text-cs-gray">
            {isSuccessResetPassword ? (
              <span className="text-sm font-normal text-cs-gray">
                A password reset has been sent to{" "}
                <span className="font-bold text-cs-pink">
                  {user?.email ?? ""}
                </span>
                , didn't send? try again below
              </span>
            ) : (
              "If you wish to reset your password, press the link below and we'll send you an email with instructions. Only someone with access to your email account will be able to change your password."
            )}
          </p>
          <div className="w-[200px]">
            <OutlineButton disabled={isLoadingResetPassword} scale="sm">
              {isSuccessResetPassword ? "Resend Password" : "Reset Password"}
            </OutlineButton>
          </div>
        </form>
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default MyAccount;
