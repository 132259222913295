import { ReactNode, useEffect, useState } from "react";
import { SubmitHandler, set, useForm } from "react-hook-form";
import { apiSlice } from "../../../../store/apiSlice";
import { QrScanner } from "../../../../lib/qr-scanner";
import DashboardContainer from "../../../../components/amp/DashboardContainer";
import Header from "../../../../components/global/amp/Header";
import Footer from "../../../../components/global/amp/Footer";
import { PrimaryButton } from "../../../../components/global/PrimaryButton";
import { QRious } from "react-qrious";
import ModalWrapper from "../../../../components/global/ModalWrapper";
import moment from "moment";
import { useAppSelector } from "../../../../store";
import { useSearchParams } from "react-router-dom";

type TicketReservation = {
  id: string;
  booking_id: string;
  ticket_id: string;
  pricing_option_id: string;
  price: string;
  quantity: number;
  expires_at: string;
  status: string;
  created_at: string;
  updated_at: string;
  checked_in_at: string;
  reservation_reference: string;
  booking: {
    booking_reference: string;
    created_at: string;
    customer_details?: {
      firstName: string;
      lastName: string;
    };
    booking_details: {
      pricingOptionSelection: {
        selections: {
          name: string
          quantity: number
          price: number
        }[]
      }
    }
    booking_status: string;
  };
  ticket: {
    name: string;
    display_name: string;
  };
};
const scanDelay = 1500;

const ScanTicket: React.FC = () => {
  const { user } = useAppSelector((state) => state.global);
  const [getScanToken] = apiSlice.useGetReservationScanTokenMutation();
  const [ticketValue, setTicketValue] = useState<TicketReservation | null>(
    null
  );

  const pageUrl = window.location.href;

  const [scanToken, setScanToken] = useState("");
  const [searchParams] = useSearchParams();
  const scanTokenQuery = searchParams.get("scanToken");
  useEffect(() => {
    if (user) {
      getScanToken()
        .unwrap()
        .then((res) => {
          if (res.token) {
            setScanToken(res.token.token);
          }
        });
    } else {
      setScanToken(scanTokenQuery ?? "");
    }
  }, []);

  const qrCode =
    pageUrl.split("/").slice(0, 3).join("/") +
    `/ticketing/scan?scanToken=${scanToken}`;
  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Ticket Manager Scanner"
        mobilePublishButtons={false}
        showPublishingButtons={false}
        hideLogo={true}
      />

      <div className="h-[109px]"></div>

      <Container>
        <div className="w-full text-sm leading-[21px] font-normal flex flex-col gap-5">
          <h1 className="text-[22px] font-extrabold text-[#212121]">
            🎟️ Scan & Redeem Tickets
          </h1>
          <div className="flex flex-col md:items-start items-center md:flex-row gap-[30px]">
            <RedeemManually
              token={!!user ? undefined : scanToken}
              setTicketValue={setTicketValue}
            />
            <div className="flex-grow py-[26px] max-w-[466px] flex flex-col gap-5">
              <Card className="flex gap-[15px] items-center">
                <QRious
                  value={qrCode}
                  className="w-[97px] h-[97px] flex-shrink-0"
                />
                <div>
                  <div className="font-bold">Using the scanner on mobile?</div>
                  <div className="mt-2.5 font-normal">
                    Scan the QR code to the left with your camera to open the
                    scanner and get started.
                  </div>
                </div>
              </Card>
              <Card>
                <div className="font-normal">
                  <div>Tips:</div>
                  <ul className="pl-5 list-disc">
                    <li>
                      Make sure the customer’s phone brightness is on full
                    </li>
                    <li>
                      Keep the QR code within the red square, this will turn
                      green once the ticket has been successfully scanned
                    </li>
                  </ul>

                  <div className="mt-2.5">
                    Code not working?{" "}
                    <a
                      href="mailto:connect@dayoutwiththekids.co.uk"
                      className="border-b border-[#212121]"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <div className="md:overflow-x-hidden overflow-x-scroll max-w-[100vw] w-full md:w-[955px]">
            <table className="border border-[#F1F1F1] text-sm">
              <thead>
                <th className="border border-[#F1F1F1] py-5 leading-[21px] px-[15px] text-center min-w-[110px] rounded-tl-lg">
                  Order Ref.
                </th>
                <th className="border border-[#F1F1F1] py-5 leading-[21px] px-[15px] text-center min-w-[175px]">
                  Ticket Name
                </th>
                <th className="border border-[#F1F1F1] py-5 leading-[21px] px-[15px] text-center min-w-[125px]">
                  Date of Booking
                </th>
                <th className="border border-[#F1F1F1] py-5 leading-[21px] px-[15px] text-center min-w-[125px]">
                  Redemption Time
                </th>
                <th className="border border-[#F1F1F1] py-5 leading-[21px] px-[15px] text-center min-w-[120px]">
                  Lead Booker Surname
                </th>
                <th className="border border-[#F1F1F1] py-5 leading-[21px] px-[15px] text-center min-w-[290px] rounded-tr-lg">
                  Number of Tickets
                </th>
              </thead>
              <tbody>
                {ticketValue && (
                  <tr>
                    <td className="py-2.5 border border-[#F1F1F1] text-center">
                      {ticketValue?.booking?.booking_reference ?? "-"}
                    </td>
                    <td className="py-2.5 border border-[#F1F1F1] text-center">
                      {ticketValue?.ticket?.display_name ||
                        ticketValue?.ticket?.name ||
                        ""}
                    </td>
                    <td className="py-2.5 border border-[#F1F1F1] text-center">
                      {moment(
                        ticketValue?.booking?.created_at ??
                          new Date().toISOString()
                      ).format("YYYY-MM-DD")}
                    </td>
                    <td className="py-2.5 border border-[#F1F1F1] text-center">
                      {
                      ticketValue?.checked_in_at
                        ? moment(ticketValue.checked_in_at).format("HH:mm")
                        : "-"}
                    </td>
                    <td className="py-2.5 border border-[#F1F1F1] text-center">
                      {ticketValue?.booking?.customer_details?.lastName ?? "-"}
                    </td>
                    <td className="py-2.5 border border-[#F1F1F1] text-center">
                      {ticketValue.booking.booking_details.pricingOptionSelection.selections.map(s => `${s.quantity}x ${s.name}`).join(' | ')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Container>

      <div className="h-12"></div>

      <Footer />
    </div>
  );
};

export default ScanTicket;

function RedeemManually({
  className = "w-[350px]",
  setTicketValue,
  token,
}: {
  token: string | undefined;
  className?: string;
  setTicketValue: (value: TicketReservation | null) => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [noReservationFound, setNoReservationFound] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState(false);

  const [checkInReservation, { isLoading }] = apiSlice.useCheckInReservationMutation();

  const onTicketReferenceSubmit: SubmitHandler<any> = async (formValues) => {
    setTicketValue(null);
    setNoReservationFound(false);
    if (!formValues.ticketReference) return;
    let ticket: any = await checkInReservation(
      { value: formValues.ticketReference, token }
    );
    ticket = ticket.data;
    setTicketValue(ticket);
    if (!ticket) {
      setNoReservationFound(true);
    } else {
      setSuccessModal(true);
    }
  };

  const [recentlyScanned, setRecentlyScanned] = useState<boolean>(false);
  const [getReservationByQRMutation] = apiSlice.useGetReservationByQRMutation();

  async function scan(qrCode: string) {
    setTicketValue(null);
    setRecentlyScanned(true);
    setNoReservationFound(false);
    let ticket: any = await getReservationByQRMutation({
      value: qrCode,
      token,
    });
    ticket = ticket.data as any;
    if (ticket) {
      setTicketValue(ticket);
      setSuccessModal(true);
    } else {
      setNoReservationFound(true);
    }

    setTimeout(() => {
      setRecentlyScanned(false);
    }, scanDelay);
  }

  return (
    <form
      className={className}
      onSubmit={handleSubmit(onTicketReferenceSubmit)}
    >
      <ModalWrapper
        className="w-[483px] px-10 xl:px-0 py-0"
        open={successModal}
        setOpen={setSuccessModal}
        hideCloseButton={true}
        customContainerClassName="relative flex flex-col items-center w-full p-[30px] bg-white rounded-lg"
      >
        <div>
          <h3 className="text-[22px] font-bold text-black">Success!</h3>
          <p className="mt-5 text-[#212121] text-sm font-normal">
            This ticket has successfully been redeemed.
          </p>

          <div className="flex flex-row justify-end mt-5">
            <PrimaryButton
              scale="sm"
              className="w-[151px]"
              onClick={() => {
                setSuccessModal(false);
              }}
            >
              Continue
            </PrimaryButton>
          </div>
        </div>
      </ModalWrapper>
      <ModalWrapper
        className="w-[483px] px-10 xl:px-0 py-0"
        open={noReservationFound}
        setOpen={setNoReservationFound}
        hideCloseButton={true}
        customContainerClassName="relative flex flex-col items-center w-full p-[30px] bg-white rounded-lg"
      >
        <div>
          <h3 className="text-[22px] font-bold text-black">
            No Booking Found.
          </h3>
          <p className="mt-5 text-[#212121] text-sm font-normal">
            Please try again or enter the booking reference manually.
          </p>
          <p className="mt-5 text-[#212121] text-sm font-normal">
            Or advise the customer to contact the business where they purchased
            the ticket.
          </p>
          <div className="flex flex-row justify-end mt-5">
            <PrimaryButton
              scale="sm"
              className="w-[151px]"
              onClick={() => {
                setNoReservationFound(false);
              }}
            >
              Continue
            </PrimaryButton>
          </div>
        </div>
      </ModalWrapper>

      <div className="text-sm font-bold text-[#212121]">Scan QR Code</div>
      <QrScanner
        scanDelay={scanDelay}
        onError={(e) => console.log("Qr Sacn error")}
        onResult={(r) => {
          if (recentlyScanned) return;
          console.log("result", r);
          navigator.vibrate(200);
          scan(r.getText());
        }}
      />

      <div className="text-sm font-bold text-[#212121] mt-2.5">
        Not scanning? Enter the ticket reference:
      </div>
      <input
        className={`p-2 border-cs-1 w-full text-cs-gray text-sm rounded-lg mt-2.5 h-10 ${
          errors["ticketReference"]
            ? "border-cs-red"
            : "outline-cs-pink border-[#CED3CF]"
        }`}
        {...register("ticketReference")}
        type="text"
        placeholder="1704390246"
      />

      <div className="mt-2.5 flex">
        <PrimaryButton
          scale="sm"
          type="submit"
          className="ml-auto w-[151px] text-center px-2"
          loading={isLoading}
        >
          Redeem Ticket
        </PrimaryButton>
      </div>
    </form>
  );
}

function Card({
  className = "",
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <div
      className={`border border-[#F1F1F1] rounded-lg p-[15px] text-[#212121] text-sm ${className}`}
    >
      {children}
    </div>
  );
}

function Container({ children }: { children: ReactNode }) {
  return (
    <DashboardContainer>
      <section
        className={`flex flex-col  xl:mx-[70px] p-5 bg-white xl:rounded-lg`}
      >
        {children}
      </section>
    </DashboardContainer>
  );
}
