import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { z } from "zod";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import TextInput from "../../../components/form/TextInput";
import { apiSlice } from "../../../store/apiSlice";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { globalActions } from "../../../store/globalSlice";
import { UnsavedModal } from "../../../components/global/amp/UnsavedModal";
import { PrimaryButton } from "../../../components/global/PrimaryButton";
import { OutlineButton } from "../../../components/global/OutlineButton";
import { ErrorMessage } from "../../../components/global/ErrorMessage";
import { SingleAttractionTemplate } from "../../../components/global/SingleAttractionTemplate";
import { ColorInput } from "../../../components/form/ColorInput";
import { RevertToSavedModal } from "../../../components/global/RevertToSavedModal";

interface IAttractionDetails {
  showPublishingButtons: boolean;
}

const AttractionPremiumEnhanced: React.FC<IAttractionDetails> = () => {
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const [getShowRevertModal, setShowRevertModal] = useState<boolean>(false);
  const { attractionId } = useParams();
  const {
    data: getAttraction,
    isSuccess: isGetSuccess,
    isFetching,
    refetch,
    error: attractionError,
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");
  const { result } = getAttraction ?? {};

  const [updateAttraction, { isLoading, isError, isSuccess: isUpdateSuccess }] =
    apiSlice.useUpdateAttractionDraftMutation();

  const schema = z.object({
    ribbonText: z.string(),
    description: z.string(),
    topButtonText: z.string(),
    topButtonLink: z.string(),
    bottomButtonText: z.string(),
    bottomButtonLink: z.string(),
    background: z.string(),
  });

  type AttractionPricesForm = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors, isDirty, dirtyFields },
    control,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm<AttractionPricesForm>({
    resolver: zodResolver(schema),
    shouldUseNativeValidation: true,
  });

  const [isPublishing, setIsPublishing] = useState(false);
  const getPayload = () => {
    return {
      enhanced_ribbon_text: getValues("ribbonText"),
      enhanced_description: getValues("description"),
      enhanced_top_button_text: getValues("topButtonText"),
      enhanced_top_button_link: getValues("topButtonLink"),
      enhanced_bottom_button_text: getValues("bottomButtonText"),
      enhanced_bottom_button_link: getValues("bottomButtonLink"),
      enhanced_background_hex_colour: getValues("background"),
    };
  };
  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<AttractionPricesForm> = async (e) => {
      setIsPublishing(publish);
      await updateAttraction({
        id: attractionId,
        publish,
        payload: getPayload(),
      });
      if (!isSilent) {
        restartAnimation();
      }
    };

    return fn;
  };

  const { user } = useAppSelector((state) => state.global);
  const bypassQueue =
    user?.role === "ADMIN" ||
    ["ENHANCED", "PREMIUM"].includes(result?.listing_package ?? "");

  useEffect(() => {
    if (isUpdateSuccess) {
      reset(
        {
          ribbonText: result?.enhanced_ribbon_text ?? "",
          description: result?.enhanced_description ?? "",
          topButtonText: result?.enhanced_top_button_text ?? "",
          topButtonLink: result?.enhanced_top_button_link ?? "",
          bottomButtonText: result?.enhanced_bottom_button_text ?? "",
          bottomButtonLink: result?.enhanced_bottom_button_link ?? "",
          background: result?.enhanced_background_hex_colour ?? "",
        },
        { keepValues: true }
      );
      // unlockNewAttractionTab(attractionId ?? '','ATTRACTION_PRICES')
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: isPublishing
            ? bypassQueue
              ? "Published"
              : "Submitted for approval"
            : "Your changes have been saved",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: isPublishing
            ? "Cannot publish attraction"
            : "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const revertToSave = () => {
    setShowRevertModal(true);
  };

  useEffect(() => {
    if (result && isGetSuccess && !isFetching) {
      reset(
        {
          ribbonText: result?.enhanced_ribbon_text ?? "",
          description: result?.enhanced_description ?? "",
          topButtonText: result?.enhanced_top_button_text ?? "",
          topButtonLink: result?.enhanced_top_button_link ?? "",
          bottomButtonText: result?.enhanced_bottom_button_text ?? "",
          bottomButtonLink: result?.enhanced_bottom_button_link ?? "",
          background: result?.enhanced_background_hex_colour ?? "",
        },
        { keepDirty: false }
      );
      setShowRevertModal(false);
    }
  }, [result, isFetching]);

  useEffect(() => {
    document.title = "Attraction Premium/Enhanced | Day Out With The Kids";
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionError]);
  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 404
    ) {
      navigate("/not-found", { replace: true });
    }
  }, [attractionError]);

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Edit Attraction"
        mobilePublishButtons={true}
        showPublishingButtons
        triggerValidation={handleSubmit}
        saveFn={onSubmit}
        getPayload={getPayload}
        isDirty={isDirty}
      />

      <SingleAttractionTemplate result={result} attractionId={attractionId} isAttraction={true} doneWalkthrough={undefined} name={result?.name ?? ""}>
        <form onSubmit={handleSubmit(onSubmit(false))}>
          <fieldset className="inline-flex flex-col flex-wrap w-full mb-4 md:w-3/5 xl:w-1/2">
            <legend className="mb-3 text-[22px] font-extrabold text-black">
              Premium/Enhanced
            </legend>

            <TextInput
              className="w-full mt-4 group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
              control={control}
              name={"ribbonText"}
              label="Enhanced Ribbon Text"
              inputProps={{
                ...register("ribbonText"),
              }}
            />

            <div className="flex flex-col mt-6 w-full">
              <label className="text-sm font-bold text-black mb-3">
                Enhanced Description
              </label>
              <textarea
                {...register("description")}
                className="text-cs-gray text-md p-3 w-full mt-2 border-[#CFDBD5] rounded-lg border-cs-1 outline-cs-pink h-40"
              ></textarea>

              {errors["description"]?.message && (
                <div className="text-cs-red flex items-center">
                  <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
                  <span className="flex-1">
                    {errors["description"]?.message}
                  </span>
                </div>
              )}
            </div>

            <TextInput
              className="w-full mt-4 group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
              control={control}
              name={"topButtonText"}
              label="Enhanced top button Text"
              inputProps={{
                ...register("topButtonText"),
              }}
            />
            <TextInput
              className="w-full mt-4 group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
              control={control}
              name={"topButtonLink"}
              label="Enhanced top button Link"
              inputProps={{
                ...register("topButtonLink"),
              }}
            />

            <TextInput
              className="w-full mt-4 group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
              control={control}
              name={"bottomButtonText"}
              label="Enhanced bottom button Text"
              inputProps={{
                ...register("bottomButtonText"),
              }}
            />
            <TextInput
              className="w-full mt-4 group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
              control={control}
              name={"bottomButtonLink"}
              label="Enhanced bottom button Link"
              inputProps={{
                ...register("bottomButtonLink"),
              }}
            />

            <ColorInput
              control={control}
              label="Enhanced background hex colour"
              name="enhanced_background_hex_colour"
              props={register("background")}
              getter={watch("background")}
              setter={(value) => setValue("background", value)}
            />
          </fieldset>

          <div className="flex items-center gap-5 max-w-[350px] mt-5">
            <PrimaryButton scale="sm" type="submit" loading={isLoading}>
              Save changes
            </PrimaryButton>
            <OutlineButton scale="sm" type="button" onClick={revertToSave}>
              Cancel
            </OutlineButton>
          </div>

          {isSubmitted && !isValid && Object.keys(errors).length !== 0 && (
            <ErrorMessage>
              You have some errors! Scroll up to view & fix
            </ErrorMessage>
          )}
        </form>

        <RevertToSavedModal
          getShowRevertModal={getShowRevertModal}
          setShowRevertModal={setShowRevertModal}
          refetch={refetch}
        />

        <UnsavedModal
          isDirty={isDirty && JSON.stringify(dirtyFields) !== "{}"}
        />
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default AttractionPremiumEnhanced;
