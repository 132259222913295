import { type AttractionFaqsForm } from "../pages/amp/attractions/AttractionFAQs";

type KeysOf<T> = keyof T;
type FAQFacilityKeys = KeysOf<AttractionFaqsForm>;

export interface Dictionary {
  [key: string]: string;
}

export const faqPropertyDictionary: Dictionary = {
  parking: "Parking",
  toilets: "Toilets",
  babyFriendly: "Baby Friendly",
  pushchair: "Pushchair",
  dog: "Dog",
  family: "Family Friendly",
  bringFood: "Bring Food",
  buyFood: "Buy Food",
  food: "Food & Drinks",
  foodAndDrink: "Food & Drinks",
  wheelchair: "Wheelchair",
  a11y: "Accessibility",
  tickets: "Tickets",
  privateHire: "Private Hire",
  publicTransportInformation: "Public Transport Information",
  extraFaqs: "Extra FAQs",
  additionalInformation: "Additional Information",

  _completed: "Completed FAQs",
  extraFaqsNew: "Extra FAQs",
} satisfies Record<FAQFacilityKeys, string>;

export const faqQuestionsDict = {
  parking: {
    accessible: "Accessible",
    extraNotes: "Note",
    facilities_nearby_parking: "Nearby Parking",
    facilities_no_parking: "No Parking",
    facilities_onsite_parking: "Onsite Parking",
    facilities_parking: "Parking Available",
    fees: "Fees",
    location: "Location",
    locationNote: "Location note",
    prebooked: "Pre-booked Parking",
  } satisfies Record<KeysOf<AttractionFaqsForm["parking"]>, string>,
  toilets: {
    accessibleNote: "Accessibility Note",
    easy_to_find: "Is easy to find",
    extraNotes: "Note",
    facilities_accessible_toilets: "Accessible",
    facilities_baby_changing: "Baby Changing",
    facilities_toilets: "Toilets Available",
    maintained: "Toilets Maintained",
    facilities_baby_changing_note: 'Baby Changing Note'
  } satisfies Record<KeysOf<AttractionFaqsForm["toilets"]>, string>,
  babyFriendly: {
    extraNotes: "Note",
    facilities_baby_changing: "Baby Changing",
  } satisfies Record<KeysOf<AttractionFaqsForm["babyFriendly"]>, string>,
  pushchair: {
    extraNotes: "Note",
    facilities_pushchair_friendly: "Pushchair Friendly",
  } satisfies Record<KeysOf<AttractionFaqsForm["pushchair"]>, string>,
  dog: {
    extraNotes: "Note",
    pet_friendly: "Pet Friendly",
  } satisfies Record<KeysOf<AttractionFaqsForm["dog"]>, string>,
  family: {
    babyFriendly: "Baby Friendly",
    babyPlay: "Baby Play",
    breastfeedingRestroom: "Breastfeeding Restroom",
    breastfeedingRestroomNote: "Breastfeeding Restroom Note",
    dog: "Dogs",
    prams: "Prams",
    pushchair: "Pushchair",
  } satisfies Record<KeysOf<AttractionFaqsForm["family"]>, string>,
  bringFood: {
    extraNotes: "Note",
    facilities_picnics_allowed: "Picnics Allowed",
  } satisfies Record<KeysOf<AttractionFaqsForm["bringFood"]>, string>,
  buyFood: {
    extraNotes: "Note",
    facilities_food: "Can buy food",
    facilities_no_food_available: "No food available",
    facilities_onsite_restaurant: "Onsite restaurant",
    facilities_snacks_available: "Snacks Available",
  } satisfies Record<KeysOf<AttractionFaqsForm["buyFood"]>, string>,
  food: {
    extraNotes: "Note",
    facilities_food: "Can buy food",
    facilities_no_food_available: "No food available",
    facilities_onsite_restaurant: "Onsite restaurant",
    facilities_snacks_available: "Snacks Available",
    
    averageCost: "Average Cost",
    averageCostNote: "Average Cost Note",
    bringInFoodRestrictions: "Bring in food restrictions",
    healthyOptions: "Healthy Options",
    kidsHighChairs: "Kids Highchair",
    picnic: "Picnic",
    pricingPoints: "Pricing Points",
    warmUpPlace: "Warmup place",
  } satisfies Record<KeysOf<AttractionFaqsForm["food"]>, string>,
  foodAndDrink: {
    extraNotes: "Note",
  } satisfies Record<KeysOf<AttractionFaqsForm["foodAndDrink"]>, string>,
  wheelchair: {
    extraNotes: "Note",
    facilities_wheelchair_friendly: "Wheelchair Friendly",
  } satisfies Record<KeysOf<AttractionFaqsForm["wheelchair"]>, string>,
  a11y: {
    allAreas: "All areas accessible",
    rampLifts: "Ramplifts",
    wheelchairAssist: "Wheelchair Assist",
    wheelchairAssistNote: "Wheelchair Assist Note",
    wheelchairRent: "Wheelchair Rent",
    wheelchairSeating: "Wheelchair Seating",
  } satisfies Record<KeysOf<AttractionFaqsForm["a11y"]>, string>,
  tickets: {
    discount: "Discount",
    extraNotes: "Note",
    prebook_tickets: "Can prebook",
    sell_tickets: "Sell tickets",
  } satisfies Record<KeysOf<AttractionFaqsForm["tickets"]>, string>,
  privateHire: {
    catering: "Catering",
    cateringNote: "Catering Note",
    extraNotes: "Note",
    maxGuests: "Maximum Guests",
    maxGuestsNote: "Maximum Guests Note",
    packages: "Packages",
    private_hire: "Private Hire",
    privateRoom: "Private Rooms",
  } satisfies Record<KeysOf<AttractionFaqsForm["privateHire"]>, string>,
  publicTransportInformation: {
    available: "Available",
    closestStop: "Closest Stop",
    extraNotes: "Note",
    frequency: "Frequency",
  } satisfies Record<
    KeysOf<AttractionFaqsForm["publicTransportInformation"]>,
    string
  >,
  extraFaqs: {},
  additionalInformation: {
    extraNotes: "Note",
  } satisfies Record<
    KeysOf<AttractionFaqsForm["additionalInformation"]>,
    string
  >,

  _completed: "Completed FAQs",
  extraFaqsNew: "Extra FAQs",
} satisfies Record<FAQFacilityKeys, any>;

export const faqExtraNesting = {
  parking: {
    fees: {
      charge: "Charge",
      value: "Are there fees?",
      note: 'Note'
    } satisfies Record<KeysOf<AttractionFaqsForm["parking"]["fees"]>, string>,
  },
  family: {
    prams: {
      allowed: "Allowed",
      forHire: "For Hire",
    } satisfies Record<KeysOf<AttractionFaqsForm["family"]["prams"]>, string>,
    pushchair: {
      parking: "Parking",
      showArea: "Show Area",
    } satisfies Record<
      KeysOf<AttractionFaqsForm["family"]["pushchair"]>,
      string
    >,
    dog: {
      breed: "Breed Requirement",
      breedNote: "Breed Note",
      fee: "Fee?",
      feePrice: "Fee Price",
      lead: "Lead Requirement",
      water: "Water station",
    } satisfies Record<KeysOf<AttractionFaqsForm["family"]["dog"]>, string>,
  },
};

export const daysOfTheWeekMapping = {
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
};

export const timesOfDayMapping = {
  afternoon: "Afternoon",
  evening: "Evening",
  morning: "Morning",
};

export const pricingCategoryMapping: Dictionary = {
  "3_and_under": "3 & Under",
  younger_children: "Younger Children",
  adult: "Adult",
};
